import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { LanguageService } from '@services/internal/language.service';
import { InventoryImportJob, PageableModel } from '@shared/models';
import { InventoryImportPageable } from '@shared/models/build-models/pageable/inventory-import-pageable';
import { TIcons } from '@shared/type/icons.type';
import { DisplayImportName } from '@shared/utils/form-elements/display-with-autocomplete';
import { BehaviorSubject, finalize, take, tap } from 'rxjs';
import { ImportService } from 'src/app/features/inventory/import/services/import.service';
import { DefaultAutoCompleteSize } from '@constants';

@Component({
  selector: 'app-import-autocomplete',
  templateUrl: './import-autocomplete.component.html',
  styleUrls: ['./import-autocomplete.component.scss'],
  standalone: false
})
export class ImportAutocompleteComponent {
  @Input() filterView: boolean = false;
  @Input() showClearIcon: boolean = false;
  @Input() required: boolean = false;
  @Input() label: string = LanguageService.instant('inventories.importLabel');
  @Input() import: InventoryImportJob = new InventoryImportJob();
  @Input() disabled: boolean = false;

  @Output() selectedOptionEmitter = new EventEmitter<InventoryImportJob>();
  @Output() clearInputEmitter = new EventEmitter<void>();

  private importService = inject(ImportService);

  initialLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchedData$: BehaviorSubject<InventoryImportJob[]> = new BehaviorSubject<InventoryImportJob[]>([]);
  forceBlurEvent: boolean = false;

  readonly displayFn = DisplayImportName;

  selectOption(data: InventoryImportJob): void {
    this.selectedOptionEmitter.emit(data);
  }

  doIconAction(icon: TIcons): void {
    if (icon === 'close') {
      this.clearInputEmitter.emit();
      this.searchImport('', true);
    }
  }

  searchImport(value: string, skipSelectSingleOption: boolean = false): void {
    this.initialLoading$.next(false);
    this.forceBlurEvent = false;

    if (this.loading$.value) {
      return;
    }

    this.loading$.next(true);
    this.searchedData$.next([]);

    const queryParams: InventoryImportPageable = new InventoryImportPageable({ size: DefaultAutoCompleteSize, sort: 'importName,asc' });

    queryParams.importStatus = 'SUCCESS';

    if (value) {
      queryParams.importName = value;
    }

    this.importService
      .getPageable(queryParams)
      .pipe(
        take(1),
        tap((data: PageableModel<InventoryImportJob>) => {
          this.searchedData$.next(data.content);

          if (skipSelectSingleOption && this.searchedData$.value.length === 1) {
            this.forceBlurEvent = true;
            return;
          }

          if (!value && this.searchedData$.value.length === 1) {
            this.selectOption(this.searchedData$.value[0]);
            this.forceBlurEvent = true;
          } else {
            this.forceBlurEvent = false;
          }
        }),
        finalize(() => this.loading$.next(false))
      )
      .subscribe();
  }
}
