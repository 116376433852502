import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CatalogModel } from '@shared/models';

@Component({
  selector: 'app-create-quote-selected-items',
  templateUrl: './create-quote-selected-items.component.html',
  styleUrls: ['./create-quote-selected-items.component.scss'],
  standalone: false
})
export class CreateQuoteSelectedItemsComponent {
  @Input() selectedCatalogs: CatalogModel[] = [];
  @Input() disableControls: boolean = false;

  @Output() removeCatalogEmitter = new EventEmitter<CatalogModel>();
}
