import { IFilter } from '@shared/interfaces';
import { DefaultPagination } from '@constants';

export class BasePageableParams {
  page: number;
  size: number;
  sort: string;

  constructor(params: IFilter) {
    return BasePageableParams.build(params);
  }

  private static build(params: IFilter): BasePageableParams {
    return {
      page: params?.page || 0,
      size: params?.size || (DefaultPagination > 100 ? 100 : DefaultPagination),
      sort: params?.sort || 'createdDatetime,asc'
    };
  }
}
