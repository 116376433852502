import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from '@services/internal/permission.service';
import { PermissionDirectiveModel, PermissionModel } from '@shared/models/permissions/permission.model';
import { PermissionNamesType } from '@shared/type/permission.type';
import { pairwise } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[appHasPermission]',
  standalone: false
})
export class HasPermissionDirective {
  @Input('appHasPermission') set appHasPermission(permissions: PermissionDirectiveModel) {
    this.isGranted(permissions.scope, permissions.permissionName, permissions.conversely);
    this.currentPermissions = permissions;
  }
  private currentPermissions: PermissionDirectiveModel;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService
  ) {
    this.permissionService.permissionUpdated$.pipe(pairwise(), takeUntilDestroyed()).subscribe(() => {
      this.isGranted(this.currentPermissions.scope, this.currentPermissions.permissionName, this.currentPermissions.conversely);
    });
  }

  /**
   * Check and show/clear template
   */
  private isGranted(permissionScope: keyof PermissionModel, permission: PermissionNamesType, conversely: boolean = false): void {
    // conversely = revert, opposite according to current permission
    const currentPermission = this.permissionService.isGranted(permissionScope, permission);
    const showTemplate = (currentPermission && !conversely) || (!currentPermission && conversely);
    // Clear template in all cases, because viewContainer.createEmbeddedView creates/inserts a new template
    this.clearTemplate();
    if (showTemplate) {
      this.showTemplate();
    }
  }

  private showTemplate(): void {
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private clearTemplate(): void {
    this.viewContainer.clear();
  }
}
