import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getMapStyles } from '@shared/modules/maps/utils/map-styles';
import { GoogleApiService } from '@shared/modules/maps/services/google-api.service';
import { Observable } from 'rxjs';
import { TrackById } from '@shared/utils/form-elements/track-by';
import { LocationModel } from '@shared/models';

@Component({
  selector: 'app-modal-map-view',
  templateUrl: './modal-map-view.component.html',
  styleUrls: ['./modal-map-view.component.scss'],
  standalone: false
})
export class ModalMapViewComponent {
  apiLoaded: Observable<boolean>;
  trackBy = TrackById;
  loading: boolean = false;
  options: google.maps.MapOptions = {
    zoom: 6,
    minZoom: 4,
    mapTypeControl: false,
    streetViewControl: false,
    styles: getMapStyles()
  };
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  markerPosition: google.maps.LatLngLiteral;

  constructor(
    public dialogRef: MatDialogRef<ModalMapViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LocationModel,
    private googleApiService: GoogleApiService
  ) {
    this.apiLoaded = this.googleApiService.loadGoogleMapsAPI();
    this.markerPosition = {
      lat: parseFloat(data.latitude),
      lng: parseFloat(data.longitude)
    };
  }
}
