import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-wysiwyg',
  templateUrl: './wysiwyg.component.html',
  styleUrls: ['./wysiwyg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class WysiwygComponent {
  @Input() maxLength: number = null;
  @Input() value: string = '';
  @Input() editValueFlow: boolean = false;
  @Input() set innerHtml(data: string) {
    if (!data) {
      return;
    }

    setTimeout(() => {
      const wysiwyg: Element = document.querySelector('.editView .ql-editor');
      wysiwyg.innerHTML = data;
    });
  }
  @Input() modalView: boolean = false;

  @Output() valueChanged = new EventEmitter<string>();
}
