import { ECompanyTypes } from '@shared/enum';
import { PermissionNamesType, TRoles } from '@shared/type/index.type';

export class PermissionConfigModel {
  companyType: ECompanyTypes;
  permissionsWasSet: boolean = false;
  roleName: TRoles;
  validRole: boolean;
  offlineMode?: boolean;
}

export class PermissionDirectiveModel {
  scope: keyof PermissionModel;
  permissionName: PermissionNamesType;
  conversely?: boolean;
}

export class CommonPermissions {
  visitDetailPageAssignedEntity?: boolean;
  addComment?: boolean = false;
}

export class InventoryPermissions {
  editInventory?: boolean = true;
  acceptSelfTransferOnly?: boolean = false;
  approveAnyTransfer?: boolean = false;
  canAdd_IFY_ToProductLine?: boolean = false;
  canCreateCatalog?: boolean;
  canCreateInventoryForSelfOnly?: boolean = false;
  canCreateInventoryImport?: boolean = false;
  canCreateProductLine?: boolean;
  canEditCatalog?: boolean = false;
  canEditInventoryAdditionalInfo?: boolean = false;
  canEditInventoryPriceInfo?: boolean = false;
  canEditInventoryPriceInfoForSelfCreatedOnly?: boolean = false;
  canEditPriceOfInventoryInDUT?: boolean = false;
  canEditProductLine?: boolean = false;
  canFilterDevicesByCustody?: boolean = false;
  canFilterInventoriesByCustody?: boolean = false;
  canFilterInventoriesByHoldStatus?: boolean = false;
  canFulfillInventoryRequest?: boolean = false;
  canInitiateInventoryRequest?: boolean = false;
  canMakeTransferFromIndividualToOrganization?: boolean = false;
  canMarkInventoryAsUsedInEvent?: boolean = false;
  canReadCOG?: boolean = false;
  canReadInventoryImport?: boolean = false;
  canReadParLevel?: boolean = false;
  createInventory?: boolean = false;
  createInventoryTransfer?: boolean = false;
  createInventoryTransferFromAnyone?: boolean = false;
  createInventoryTransferFromSelfOnly?: boolean = false;
  createInventoryRequestWithManufacturerWithoutEmail?: boolean = false;
  filterByCustodyInHisGroup?: boolean = false;
  canCreateInventoryRequestToOrgOnly?: boolean = false;
  canManageInventoryAtRequestDetailPage?: boolean = false;
  readTransferShippingCosts?: boolean = false;
  filterTransfersBySender?: boolean = false;
  filterTransferByRecipient?: boolean = false;
  filterProductLinesByAssignedToCurrentUser?: boolean = false;
  manageHoldStatus?: boolean = false;
  assignEventToTransfer?: boolean = false;
  setDueBackDateInTransfer?: boolean = false;
  readFieldTransferFlag?: boolean = false;
  readInventoryValue?: boolean = false;
  createBOM?: boolean = false;
  //offline
  filterInventories?: boolean = false;
  readCatalogDetail?: boolean = false;
  readProductLineDetail?: boolean = false;
  readImportDetail?: boolean = false;
  readEventDetail?: boolean = false;
  markDeviceInEvent?: boolean = false;
  createAudits?: boolean = false;
  readAudits?: boolean = false;
  scanViaUDIdentify?: boolean = false;
  manageInventoryRequestInProgress?: boolean = false;
}

export class EventsPermissions {
  canAddPONumber?: boolean = false;
  canChangeCommissionAndInvoicePaid?: boolean = false;
  canChangeEventStatus?: boolean = false;
  canChangeEventStatusOnlyFromClosedToCompleted?: boolean = false;
  canCloseEvent?: boolean = false;
  canCreateEventForSelfOnly?: boolean = false;
  canEditEvent?: boolean = false;
  canGenerateInvoice?: boolean = false;
  canMarkAsPaid?: boolean = false;
  canScheduleEvent?: boolean = false;
  canSignEvent?: boolean = false;
  filterByRepresentativeInHisGroup?: boolean = false;
  canExportSalesOrderFormToQuickBooks?: boolean = false;
  canReadUsers?: boolean = false;
  canReadDUTPriceHasChangedByNonAdmin?: boolean = false;
  canReadStockAdjustment?: boolean = false;
  canSendSalesOrderAsAttachment?: boolean = true;
  canSendInvoiceAsAttachment?: boolean = true;
  canSendQuoteAsAttachment?: boolean = true;
  //offline
  toggleCalendarView?: boolean = false;
  filterEvents?: boolean = false;
  createEvent?: boolean = false;
  seeImages?: boolean = false;
  editPriceAdjustments?: boolean = false;
  readTotalValue?: boolean = false;
  signEventUsingCanvas?: boolean = false;
  editPONUmber?: boolean = false;
  deviceUsedBulkActions?: boolean = false;
  submitForBillingRequestReplenishment?: boolean = false;
  useAdditionalFiltersOnMUI?: boolean = false;
  copyEvent?: boolean = false;
  downloadInvoiceAndSOFInReadOnlyMode?: boolean;
}

export class UserModelPermission {
  addUsersToSystem?: boolean = false;
  canDeactivateUsers?: boolean = false;
  canEditShippingAddress?: boolean = false;
  canReadUsers?: boolean = false;
  readUserCommission?: boolean = false;
  changeUserRole?: boolean = false;
  readUsersStatistics?: boolean = false;
  filterUsersByRole?: boolean = false;
  sendPOReminder?: boolean = false;
  canCreateCommissionOverride?: boolean = false;
  requestAdditionalSeats?: boolean = false;
  unLockUser?: boolean = false;
  toggleCustodianOnly?: boolean = false;
  organizationTree?: boolean = false;
}

export class MovementsPermission {
  createRequestRecipe?: boolean = false;
  createLocation?: boolean = false;
  filterProductsByRequester?: boolean = false;
}

export class SettingsPermission {
  editOrgAccountInfo?: boolean = false;
  editSelfIndividualAccountInfo?: boolean = false;
  writeStartInvoiceNumber?: boolean = false;
  readIntegrations?: boolean = false;
  canEditEmailDigest?: boolean = false;
  sendWeeklyPOReminder?: boolean = false;
  editCustomPermissions?: boolean = false;
  canEditBilledManufacturers?: boolean = false;
  readSettings?: boolean = true;
}

export class NotificationsPermissions {
  readNotifications?: boolean = true;
}

export class DirectoryPermission {
  canAddNotesToFacility?: boolean = false;
  canAddNotesToPhysician?: boolean = false;
  canAddNotesToProcedure?: boolean = false;
  canEditSelfOnlyFacility?: boolean = false;
  canEditSelfOnlyPhysician?: boolean = false;
  canEditSelfOnlyProcedure?: boolean = false;
  canNotesToFacilityOnlySelfCreated?: boolean = false;
  canNotesToPhysicianOnlySelfCreated?: boolean = false;
  canNotesToProcedureOnlySelfCreated?: boolean = false;
  canUploadFacilityPrice?: boolean = false;
  createFacility?: boolean = false;
  createManufacturer?: boolean = false;
  createPhysician?: boolean = false;
  createProcedure?: boolean = false;
  editFacility?: boolean = false;
  editManufacturer?: boolean = false;
  editPhysician?: boolean = false;
  editProcedure?: boolean = false;
  deactivateManufacturer?: boolean = false;
  canReadCustomerIdAtFacilityPage?: boolean = false;
  managePriceSheets?: boolean = false;
  markFacilityContract?: boolean = false;
  readRegions?: boolean = false;
  //Uses in offline mode
  readFacilityDetail?: boolean;
  readPhysicianDetail?: boolean;
  readProcedureDetail?: boolean;
  readManufacturerDetail?: boolean;
  readPriceSheets?: boolean;
  editCreditHold?: boolean;
}

export class ExportTaskPermission {
  readExportTasks?: boolean = false;
}

export class PermissionReportModel {
  //For all roles, reports can be toggles at user level settings or in super admin.
  readReport?: boolean = false;
  readCaseReport?: boolean = false;
  readParLevelReport?: boolean = false;
  readContainerTurnReport?: boolean = false;
  readTransferReport?: boolean = false;
  inventoryReport?: boolean = false;
  readTotalSalesReport?: boolean = false;
  readCustomSalesReport?: boolean = false;
  usersReport?: boolean = false;
  productLinesReport?: boolean = false;
  catalogsReport?: boolean = false;
  exportInventory?: boolean = false;
  facilitiesReport?: boolean = false;
  physiciansReport?: boolean = false;
  inventoryRequestReport?: boolean = false;
  readInventoryReorderReport?: boolean = false;
  accessRestricted?: boolean = false;
  directoryGroupReports?: boolean;
  movementsGroupReports?: boolean;
  inventoryAging?: boolean;
  weightedAverageCost?: boolean;
  facilityStock?: boolean;
}

export class DashboardPermission {
  seeTrackingActionsWidget?: boolean = false;
  seeSalesPerEventWidget?: boolean = false;
  filterByRepresentative?: boolean = false;
  readTotalSalesWidget?: boolean = false;
  readSalesByRepresentativeWidget?: boolean = false;
  readExpirationsByCustodyWidget?: boolean = false;
  readTopUsedCatalogsWidget?: boolean = false;
  readSalesTargetWidget?: boolean = false;
  selectUserSalesTargetWidget?: boolean = false;
  readInventoryExtensionRequestWidget?: boolean = false;
}

export class CustomPermissions {
  addAndDeactivateInventoriesEnabled: boolean = false;
  initiateTransfersEnabled: boolean = false;
  reopenEventsEnabled: boolean = false;
  requireAssociateRequestsWithEvents: boolean = false;
  requireEventNotesEnabled: boolean = false;
}

export class UserLevelPermissions {
  userSettingUpdateAllowed: boolean = true; // ADMIN
  inventoryCreationAllowed: boolean = true; // ADMIN
  removeExpirationDateAllowed: boolean = false; // ADMIN
  createAndInviteUserAllowed: boolean = true; // ADMIN + RM + DISTRIBUTOR
  manageUserAllowed: boolean = true; // ADMIN + DISTRIBUTOR
  invDeactivationAndStockEventCreationAllowed: boolean = true; // ADMIN
  editContainerCatalogNumberAllowed: boolean = false; // ADMIN
  editCatalogNumberAllowed: boolean = false; // ADMIN
  manageEventsAllowed: boolean = true; // ADMIN + RM
  manageInventoryMovementsAllowed: boolean = true; // ADMIN + RM
  manageFacilityPricingAllowed: boolean = true; // ADMIN
  manageDirectoryItemsAllowed: boolean = true; // ADMIN
  viewReportsAllowed: boolean = true; // ADMIN + RM + DISTRIBUTOR
  manageIntegrationsAllowed: boolean = true; // ADMIN
  viewCommissionsAllowed: boolean = true; // RM + DISTRIBUTOR
  changePriceAllowed: boolean = true; // DISTRIBUTOR + SALES
  requirePatientStickerUploadAllowed: boolean = false; // DISTRIBUTOR + SALES
  requireDeviceLabelUploadedAllowed: boolean = false; // DISTRIBUTOR + SALES
  addDirectoryItemsAllowed: boolean = true; // DISTRIBUTOR + SALES
  manageBomsAllowed: boolean = true; // DISTRIBUTOR + SALES
  modifyReplenishmentRequestsAllowed: boolean = true; // DISTRIBUTOR + SALES
  viewFacilityContractPricesAllowed: boolean = true; // RM + DISTRIBUTOR + SALES
  transferInventoryFromCustodianOnlyAllowed: boolean = false; // RM + DISTRIBUTOR + SALES
}

export class PermissionModel {
  config: PermissionConfigModel;
  common?: CommonPermissions;
  dashboard?: DashboardPermission;
  directory?: DirectoryPermission;
  events?: EventsPermissions;
  exportTask?: ExportTaskPermission;
  forbiddenRoutes?: string[];
  inventory?: InventoryPermissions;
  report?: PermissionReportModel;
  settings?: SettingsPermission;
  user?: UserModelPermission;
  movements?: MovementsPermission;
  custom?: CustomPermissions;
  userLevel?: UserLevelPermissions;
  notifications?: NotificationsPermissions;

  constructor() {
    this.config = new PermissionConfigModel();
    this.dashboard = new DashboardPermission();
    this.directory = new DirectoryPermission();
    this.events = new EventsPermissions();
    this.exportTask = new ExportTaskPermission();
    this.forbiddenRoutes = [];
    this.inventory = new InventoryPermissions();
    this.report = new PermissionReportModel();
    this.settings = new SettingsPermission();
    this.user = new UserModelPermission();
    this.movements = new MovementsPermission();
    this.custom = new CustomPermissions();
    this.userLevel = new UserLevelPermissions();
    this.notifications = new NotificationsPermissions();
  }
}
