import { animate, state, style, transition, trigger } from '@angular/animations';

export const expandCollapseAnimation = trigger('expandCollapse', [
  state(
    'open',
    style({
      height: '*'
    })
  ),
  state(
    'close',
    style({
      height: '0px',
      opacity: '0'
    })
  ),
  transition('open <=> close', animate(350))
]);
