<div
  class="infoDataBlockContainer"
  [ngClass]="{ hideBorder: hideBorder }"
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
  fxLayoutGap="8px"
>
  <div class="infoDataBlockCardsContainer" fxLayout.xs="column">
    @for (item of additionalData; track item.key; let last = $last) {
      @if (item?.key) {
        <div [ngClass]="{ border: last }" fxLayout="column" class="infoDataBlockCard" fxFlex.xs="100%">
          <span class="itemTitle">{{ item.key }}</span>
          <span *ngIf="data?.createdDatetime; else skeleton" class="itemValue" [attr.data-cy]="item.dataCy || ''">
            @if (item?.routerLink) {
              <a [routerLink]="item?.routerLink">{{ item.value }}</a>
            } @else {
              {{ item.value }}
            }
          </span>
        </div>
      }
    }
    <div *ngIf="data?.createdBy" fxLayout="column" class="infoDataBlockCard" fxFlex.xs="100%">
      <span class="itemTitle">{{ 'shared.labels.infoBlockCreatedBy' | language }}</span>
      <span *ngIf="data?.createdDatetime; else skeleton" class="itemValue" data-cy="createdBy">
        {{ data?.createdBy?.name }}
      </span>
    </div>
    <div fxLayout="column" class="infoDataBlockCard border" fxFlex.xs="100%">
      <span class="itemTitle">{{ 'shared.labels.infoBlockCreated' | language }}</span>
      <span *ngIf="data?.createdDatetime; else skeleton" class="itemValue">{{ data?.createdDatetime | userDate }}</span>
    </div>

    <ng-container *ngIf="showModified">
      <div *ngIf="data?.modifiedBy" fxLayout="column" class="infoDataBlockCard" fxFlex.xs="100%">
        <span class="itemTitle">{{ 'shared.labels.infoBlockModifiedBy' | language }}</span>
        <span *ngIf="data?.createdDatetime; else skeleton" class="itemValue">{{ data?.modifiedBy?.name }}</span>
      </div>
      <div fxLayout="column" class="infoDataBlockCard border" fxFlex.xs="100%">
        <span class="itemTitle">{{ 'shared.labels.infoBlockModified' | language }}</span>
        <span *ngIf="data?.createdDatetime; else skeleton" class="itemValue">{{ data?.modifiedDatetime | userDate }}</span>
      </div>
    </ng-container>
    <div *ngIf="containersPossible !== null" fxLayout="column" class="infoDataBlockCard" fxFlex.xs="100%">
      <span class="itemTitle" [innerHTML]="'shared.labels.infoBlockContainersPossible' | language"></span>
      <span *ngIf="data?.createdDatetime; else skeleton" class="itemValue"> {{ containersPossible }}</span>
    </div>
    <div *ngIf="facilityPricingUpdatedOn" fxLayout="column" class="infoDataBlockCard" fxFlex.xs="100%">
      <span class="itemTitle">{{ 'facilities.priceLastUpdateLabel' | language }}</span>
      <span class="itemValue">{{ facilityPricingUpdatedOn | userDate }}</span>
    </div>
  </div>

  <app-info-anchors [anchors]="anchors" />
</div>

<ng-template #skeleton>
  <app-string-skeleton />
</ng-template>
