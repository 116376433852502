import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { TIcons } from '@shared/type/index.type';

@Component({
  selector: 'app-masked-input-kit',
  templateUrl: './masked-input-kit.component.html',
  styleUrls: ['./masked-input-kit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaskedInputKitComponent),
      multi: true
    }
  ],
  standalone: false
})
export class MaskedInputKitComponent implements ControlValueAccessor {
  @Input() icons: TIcons[] = [];
  @Input() placeholder: string = '';
  @Input() label: string = 'Label';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() dataCy: string = '';
  @Input() hint: string = '';
  @Input() error: string = '';
  @Input() type: string = 'text';
  @Input() set initialValue(data: any) {
    if (data || data === 0 || data === null) {
      this.value = data;
    }
  }
  @Input() tooltipText: TranslationsKeys;
  @Input() size: 'medium' | 'small' = 'medium';
  @Input() readonly: boolean = false;
  @Input() mask: string = null;
  @Input() thousandSeparator: string = null;
  @Input() validation: boolean = true;
  @Input() allowNegativeNumbers: boolean = false;
  @Input() min: number = null;
  @Input() maxLength: number = null;
  @Input() borderTransparent: boolean = false;
  @Input() dropSpecialCharacters: boolean = true;

  @Output() iconClickEmitter = new EventEmitter<TIcons>();
  @Output() valueChangedEmitter = new EventEmitter<any>();
  @Output() blurEmitter = new EventEmitter<any>();
  @Output() focusEmitter = new EventEmitter<void>();
  @Output() barcodeEmitter = new EventEmitter<string>();
  @Output() enterEmitter = new EventEmitter<void>();

  focused: boolean = false;
  value: string = '';

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  updateValue(value: any) {
    if (value !== undefined && this.value !== value) {
      if (typeof value === 'string') {
        value = value.trim();
      }
      this.valueChangedEmitter.emit(value);
      this.value = value;
      this.onChange(value);
      this.onTouch();
    }
  }
}
