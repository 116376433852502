import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IFilter } from '@shared/interfaces';
import { NotificationModel } from '@shared/models';
import { SearchParamsShorterObject } from '@shared/utils/http/search-params-shorter';
import { TrackById } from '@shared/utils/form-elements/track-by';
import { addDays, subDays } from 'date-fns';
import { UsersService } from '@services/users.service';

@Component({
  selector: 'app-list-notifications',
  templateUrl: './list-notifications.component.html',
  styleUrls: ['./list-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ListNotificationsComponent {
  @Input({ required: true }) notifications: NotificationModel[] = [];
  @Input() loading: boolean = false;

  @Output() viewEmitter = new EventEmitter<void>();

  private router = inject(Router);
  private sanitizer = inject(DomSanitizer);

  readonly trackBy = TrackById;
  readonly isAdmin: boolean = UsersService.getUser().role === 'ADMIN';

  isActive = (url: string): boolean => this.router.url.includes(url);

  defineInspectionDue(message: string): void {
    const params: IFilter = {};

    if (message.includes('next 7 days')) {
      params.inspectionDaysFrom = 7;
      params.inspectionDaysTo = 7;
    } else {
      params.inspectionDays = 'OVERDUE';
    }

    this.router.navigate(['/inventory/inventories/list'], { queryParams: SearchParamsShorterObject(params) });
  }

  defineDueBackStatus(message: string): void {
    const dueBackStatus = message.includes('in 24 hours') ? 'IN_24_HOURS' : message.includes('in 48 hours') ? 'IN_48_HOURS' : 'OVERDUE';
    this.router.navigate(['/inventory/inventories/list'], { queryParams: SearchParamsShorterObject({ dueBackStatus }) }).then();
  }

  defineReservationDate(message: string, datetime: string): void {
    let datetimeFrom: string;
    let datetimeTo: string;

    if (message.includes('in 24 hours')) {
      datetimeFrom = subDays(new Date(datetime), 1).toISOString();
      datetimeTo = datetime;
    } else {
      datetimeFrom = datetime;
      datetimeTo = addDays(new Date(datetime), 2).toISOString();
    }

    this.router.navigate(['/inventory/inventories/list'], {
      queryParams: SearchParamsShorterObject({
        itemType: 'CONTAINER',
        showReservedContainers: true,
        eventDatetimeFrom: datetimeFrom,
        eventDatetimeTo: datetimeTo
      })
    });
  }

  navigateToDueBackExtension(): void {
    this.router.navigate(['/inventory/inventories/list'], { queryParams: SearchParamsShorterObject({ isExtensionRequested: true }) });
  }

  bypassSecurityTrustHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getMentionedPage(notification: NotificationModel): string {
    if (notification.entityType === 'PHYSICIAN') {
      return '/directory/physicians/edit/' + notification.entityId;
    } else if (notification.entityType === 'TRANSFER') {
      return '/movement/transfers/edit/' + notification.entityId;
    } else if (notification.entityType === 'EVENT') {
      return '/events/edit/' + notification.entityId;
    } else if (notification.entityType === 'REQUEST') {
      return '/movement/requests/edit/' + notification.entityId;
    } else if (notification.entityType === 'INVENTORY') {
      return '/inventory/inventories/edit/' + notification.entityId;
    } else {
      return '';
    }
  }
}
