import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { PermissionService } from '@services/internal/permission.service';
import { InventoryModel, SimilarInventoryInfo, UserModel } from '@shared/models';
import { BehaviorSubject } from 'rxjs';
import { GetDifferenceInDays } from '@shared/utils/date/get-diff-days';

@Component({
  selector: 'app-inventory-selected-short-model-with-count',
  templateUrl: './inventory-selected-short-model-with-count.component.html',
  styleUrls: ['./inventory-selected-short-model-with-count.component.scss'],
  standalone: false
})
export class InventorySelectedShortModelWithCountComponent {
  @Input() companyName: string = '';
  @Input() ind: number = 0;
  @Input('item') set inventory(data: SimilarInventoryInfo) {
    this.getExpirationDays(data);
    this.item = data;
    this.tags = this.getInventoryTags(data.tags);
  }
  @Input() recipient: UserModel = new UserModel();
  @Input() selectedItems: number = 0;
  @Input() recipientOrg: boolean = false;
  @Input() fullWidthView: boolean = false;
  @Input() displayTags: boolean = false;
  @Input() custody: UserModel = new UserModel();

  @Output() chooseTransferContainerEmitter = new EventEmitter<InventoryModel>();
  @Output() removeInventoryEmitter = new EventEmitter<number>();
  @Output() selectedCountEmitter = new EventEmitter<number>();

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  tags: string[] = [];
  item: SimilarInventoryInfo;

  readonly isMobile$: BehaviorSubject<boolean> = GlobalStoreService.isMobile$;

  constructor(public permissionService: PermissionService) {}

  closeMatMenu(): void {
    this.menuTrigger.closeMenu();
  }

  removeInventory(index: number): void {
    this.removeInventoryEmitter.emit(index);
  }

  private getInventoryTags(value: string): string[] {
    if (!value) {
      return [];
    }
    return JSON.parse(value);
  }

  private getExpirationDays(data: SimilarInventoryInfo): void {
    if ((data.expirationStatus !== 'EXPIRING_SOON' && data.expirationStatus !== 'UNEXPIRED') || !data.expirationDate) {
      return;
    }

    const difference: number = GetDifferenceInDays(data.expirationDate);

    // CC-3889
    if (difference <= 60) {
      data.expiredDays = difference;
    }
  }
}
