import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { IContact, IEventPDFSendRequest } from '@shared/interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventModel, ContactModel, UserModel } from '@shared/models';
import { formatDate } from '@angular/common';
import { UsersService } from '@services/users.service';
import { TrackById } from '@shared/utils/form-elements/track-by';
import { EDateFormatPDF, EEventTypes } from '@shared/enum';
import { EmailPattern } from '@shared/validators/email-validator';
import { LanguageService } from '@services/internal/language.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { AlertsService } from '@services/internal/alerts.service';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { EventsStoreService } from '../../../../services/events-store.service';
import { TFacilityContactsType } from '@shared/type/entity-type.type';
import { take } from 'rxjs';
import { FacilityService } from '@services/facility.service';

@Component({
  selector: 'app-send-pdf-as-attachment',
  templateUrl: './send-pdf-as-attachment.component.html',
  styleUrls: ['./send-pdf-as-attachment.component.scss'],
  standalone: false
})
export class SendPdfAsAttachmentComponent implements OnInit {
  body: string;
  email: string;
  subject: string;
  replyTo: string;
  noEmailOption: boolean;
  contacts: IContact[] = [];
  CCEmails: { email: string }[] = [];
  poNumbers: string = '';
  poDate: string | Date;
  isNoReplenishment: boolean = false;
  originalFacilityContacts: ContactModel[] = [];

  readonly trackBy = TrackById;
  readonly emailPattern: RegExp = EmailPattern;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  private readonly contactsByType: { [key in TFacilityContactsType]: number } = {
    BILLING: 0,
    PRIMARY: 0,
    ADDITIONAL_PRIMARY: 0
  };

  constructor(
    private dialogRef: MatDialogRef<SendPdfAsAttachmentComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      event: EventModel;
      type: 'SALES_ORDER_FORM' | 'INVOICE' | 'EVENT_QUOTE';
      facilityContacts: ContactModel[];
      poDate: string;
      poNumbers: string[];
      title?: TranslationsKeys;
    },
    @Inject(LOCALE_ID) private locale: string,
    private alertsService: AlertsService,
    private eventsStoreService: EventsStoreService,
    private facilityService: FacilityService
  ) {
    if (this.data?.facilityContacts?.length) {
      this.processFacilityContacts();
    } else if (this.data?.event?.facility?.id && !this.data?.facilityContacts?.length) {
      this.getFacilityContacts(this.data.event.facility.id);
    }

    this.noEmailOption = this.data?.facilityContacts?.every(contact => !contact.email && !contact.additionalEmail);
    this.email = this.contacts.find(e => e.isDefault)?.email || this.contacts[0]?.email;
  }

  ngOnInit(): void {
    const user: UserModel = UsersService.getUser();

    this.replyTo = user.emailAddress;

    if (this.data.event?.representative?.emailAddress || user.organization.company.email) {
      this.selectCCEmail(this.data.event?.representative?.emailAddress || user.organization.company.email);
    }
    // poNumbers and poDate can be passed in data if a component uses outside event detail module
    if (this.data?.poNumbers) {
      this.poNumbers = this.data.poNumbers.reduce(
        (previousValue, currentValue, index) => previousValue + currentValue + (index !== this.data.poNumbers?.length - 1 ? ', ' : ''),
        ''
      );
      this.poDate = this.data.poDate;
    } else {
      const poNums = this.eventsStoreService.store$.value.poNumbers;
      this.poNumbers = poNums.reduce(
        (previousValue, currentValue, index) => previousValue + currentValue.poNumber + (index !== poNums?.length - 1 ? ', ' : ''),
        ''
      );
      this.poDate = poNums?.length ? poNums[0].poDate : '';
    }
    this.subject = this.getSubject();
    this.body = this.getBody();
  }

  getContactTypeName(item: IContact): string {
    if (item.role) {
      return item.role;
    }

    if (item.type === 'BILLING') {
      return (
        LanguageService.instant('shared.labels.billingContact') +
        (this.contactsByType[item.type] > 1 ? ' ' + String(item.order).padStart(2, '0') : '')
      );
    } else if (item.type === 'PRIMARY') {
      return LanguageService.instant('shared.labels.primaryContact');
    } else {
      return (
        LanguageService.instant('shared.labels.additionalContact') +
        (this.contactsByType[item.type] > 1 ? ' ' + String(item.order).padStart(2, '0') : '')
      );
    }
  }

  close(res?: IEventPDFSendRequest): void {
    this.dialogRef.close({ ...res, ...{ facilityContacts: this.originalFacilityContacts } });
  }

  send(): void {
    const params: IEventPDFSendRequest = {
      body: this.body + '<br/><br/>',
      email: this.email,
      eventId: this.data.event.id,
      manufacturerIds: [],
      replyTo: this.replyTo,
      subject: this.subject
    };

    if (this.CCEmails.length) {
      params.additionalEmails = this.CCEmails.map(e => e.email);
    }
    if (this.data.type === 'SALES_ORDER_FORM') {
      params.isNoReplenishment = this.isNoReplenishment;
    }

    this.close(params);
  }

  selectCCEmail(email: string): void {
    if (!email) {
      return;
    }

    if (!this.emailPattern.test(email)) {
      this.alertsService.showError('shared.errors.email', null, 5000);
      return;
    }

    if (!this.CCEmails.some(e => e.email === email)) {
      this.CCEmails.push({ email });
    }
  }

  removeCCEmail(email: string): void {
    this.CCEmails.splice(
      this.CCEmails.findIndex(e => e.email === email),
      1
    );
  }

  private processFacilityContacts(contacts: ContactModel[] = null): void {
    const cont = contacts ?? this.data?.facilityContacts;
    this.originalFacilityContacts = cont;
    cont?.forEach(c => {
      if (c.email) {
        this.defineContactsRepetition(c, 'email');
      }

      if (c.additionalEmail) {
        this.defineContactsRepetition(c, 'additionalEmail');
      }
    });

    // Sort by type. Primary -> Billing -> Additional
    this.contacts.sort((a, b) => b.type.localeCompare(a.type));
  }

  private defineContactsRepetition(contact: ContactModel, emailType: 'email' | 'additionalEmail'): void {
    const defaultCategory: 'isInvoiceDefault' | 'isSalesOrderDefault' =
      this.data.type === 'SALES_ORDER_FORM' ? 'isSalesOrderDefault' : this.data.type === 'INVOICE' ? 'isInvoiceDefault' : null;
    const isDefault: boolean = defaultCategory ? contact[defaultCategory] : false;

    this.contactsByType[contact.type]++;
    this.contacts.push({
      role: contact.role,
      type: contact.type,
      email: contact[emailType],
      isDefault,
      order: this.contactsByType[contact.type]
    });
  }

  private getBody(): string {
    const poDate: string = this.poDate
      ? formatDate(
          this.poDate,
          EDateFormatPDF[UsersService.dateFormat],
          this.locale,
          (UsersService.userTimeZone.offset / 60 / 60).toString()
        )
      : '';
    const facility: string = this.data.event.facility?.name || 'No Facility';
    const eventDate: string = formatDate(
      this.data.event.datetime,
      EDateFormatPDF[UsersService.dateFormat],
      this.locale,
      (UsersService.userTimeZone.offset / 60 / 60).toString()
    );
    const eventType: string = LanguageService.instant(EEventTypes[this.data.event.eventType]);

    // Generate body for each type
    const quoteBody: string = `Please find the attached Quote for the ${eventType},
${this.data.event.name}, scheduled for ${eventDate} at ${facility}.`;
    const salesOrderBody: string =
      `Please find the attached Sales Order Form for the
${eventType} done on ${eventDate} at ${facility}.` + (this.poNumbers ? ` PO #: ${this.poNumbers} added on ${poDate}.` : '');
    const invoiceBody: string =
      `Please find the attached Invoice for the
${eventType} done on ${eventDate} at ${facility}.` + (this.poNumbers ? ` PO #: ${this.poNumbers} added on ${poDate}.` : '');

    return this.data.type === 'EVENT_QUOTE' ? quoteBody : this.data.type === 'SALES_ORDER_FORM' ? salesOrderBody : invoiceBody;
  }

  private getSubject(): string {
    const title: string =
      this.data.type === 'SALES_ORDER_FORM'
        ? 'Sales Order Form for'
        : this.data.type === 'EVENT_QUOTE'
          ? 'Quote attached for'
          : 'Invoice for';

    return `${title} ${LanguageService.instant(EEventTypes[this.data.event.eventType])} on ${formatDate(
      this.data.event.datetime,
      EDateFormatPDF[UsersService.dateFormat],
      this.locale,
      (UsersService.userTimeZone.offset / 60 / 60).toString()
    )}${this.data.type === 'EVENT_QUOTE' ? ' on' : ','} ${this.data.event.facility?.name || 'No Facility'}${
      this.data.type !== 'EVENT_QUOTE' ? `, PO # ${this.poNumbers || 'N/A'}` : ''
    }`;
  }

  private getFacilityContacts(facilityId: string): void {
    this.facilityService
      .getFacilityContacts(facilityId)
      .pipe(take(1))
      .subscribe(contacts => {
        this.processFacilityContacts(contacts);
        this.noEmailOption = this.contacts?.every(contact => !contact.email);
        this.email = this.contacts.find(e => e.isDefault)?.email || this.contacts[0]?.email;
      });
  }
}
