import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { BehaviorSubject, finalize, take } from 'rxjs';
import { DisplayName } from '@shared/utils/form-elements/display-with-autocomplete';
import { IFilter } from '@shared/interfaces';
import { ManufacturerModel, PhysicianModel, ProcedureModel, PreferenceCardModel } from '@shared/models';
import { LanguageService } from '@services/internal/language.service';
import { RecipesPageableParams } from '@shared/models/build-models';
import { FilterUniqueArray } from '@shared/utils/arrays/filter-unique-array';
import { TIcons } from '@shared/type/icons.type';
import { RequestRecipesService } from '@services/request-recipes.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PreferenceCardDetailsComponent } from 'src/app/features/directory/preference-cards/partials/modals/preference-card-details/preference-card-details.component';
import { CreateMediumEntityConfig, DefaultAutoCompleteSize } from '@constants';
import { GlobalStoreService } from '@services/internal/global-store.service';

@Component({
  selector: 'app-preference-cards-autocomplete',
  templateUrl: './preference-cards-autocomplete.component.html',
  styleUrls: ['./preference-cards-autocomplete.component.scss'],
  standalone: false
})
export class PreferenceCardsAutocompleteComponent {
  @Input() multiple: boolean = false;
  @Input() selectedItems: PreferenceCardModel[] = [];
  @Input() removable: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() invalid: boolean = false;
  @Input() recipe: PreferenceCardModel = new PreferenceCardModel();
  @Input() label: string = LanguageService.instant('shared.components.recipe');
  @Input() showClearButton: boolean = false;
  @Input() showLinkButton: boolean = false;
  @Input() manufacturer: ManufacturerModel = new ManufacturerModel();
  @Input() physicians: PhysicianModel[] = [];
  @Input() procedure: ProcedureModel = new ProcedureModel();
  @Input() showViewInModalButton: boolean = false;
  @Input() userId: string = '';

  @Output() removeChipEmitter = new EventEmitter<string>();
  @Output() selectedOptionEmitter = new EventEmitter<PreferenceCardModel>();
  @Output() valueChangedEmitter = new EventEmitter<string>();
  @Output() clearEmitter = new EventEmitter<void>();

  private recipesService = inject(RequestRecipesService);
  private dialog = inject(MatDialog);

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initialLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  searchedData: PreferenceCardModel[] = [];
  matchEventData: boolean = false;
  forceBlurEvent: boolean = false;

  readonly isMobile$: BehaviorSubject<boolean> = GlobalStoreService.isMobile$;
  readonly displayFn = DisplayName;

  doIconAction(icon: TIcons): void {
    if (icon === 'close') {
      this.recipe = new PreferenceCardModel();
      this.clearEmitter.emit();
      this.searchRecipes('', true);
    } else if (icon === 'search') {
      this.viewDetails();
    }
  }

  searchRecipes(value: string, skipSelectSingleOption: boolean = false, page: number = 0): void {
    this.initialLoading$.next(false);
    this.forceBlurEvent = false;

    if (this.loading$.value) {
      return;
    }

    this.loading$.next(true);
    this.searchedData = [];

    const params: IFilter = {
      search: value,
      size: DefaultAutoCompleteSize,
      state: 'ACTIVE',
      page,
      sort: 'name,asc'
    };

    if (this.manufacturer?.id) {
      params.manufacturer = this.manufacturer.id;
    }

    const queryParams: RecipesPageableParams = new RecipesPageableParams(params);

    if (this.userId) {
      queryParams.userId = this.userId;
    }

    if (this.matchEventData) {
      if (this.procedure?.id) {
        queryParams.procedureId = this.procedure.id;
      }

      if (this.physicians.length) {
        queryParams.physicianIds = this.physicians.map(p => p.id);
      }
    }

    this.recipesService
      .getPageable(queryParams)
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(data => {
        if (this.multiple) {
          this.searchedData = FilterUniqueArray(data.content, this.selectedItems);
        } else {
          this.searchedData = data.content;
        }

        if (skipSelectSingleOption && this.searchedData.length === 1) {
          this.forceBlurEvent = true;
          return;
        }

        if (!value && this.searchedData.length === 1) {
          this.selectOption(this.searchedData[0]);
          this.forceBlurEvent = true;
        } else {
          this.forceBlurEvent = false;
        }
        //CC-5432 Load next page if all items selected
        if (this.searchedData?.length === 0 && data?.totalElements > this.selectedItems?.length && page < data.totalPages) {
          setTimeout(() => this.searchRecipes(value, skipSelectSingleOption, page + 1));
        }
      });
  }

  removeChip(id: string): void {
    this.removeChipEmitter.emit(id);
  }

  selectOption(item: PreferenceCardModel): void {
    this.selectedOptionEmitter.emit(item);
    if (this.multiple) {
      //Hide selected item from search results
      this.searchedData = this.searchedData.filter(c => c.id !== item.id);
    }
  }

  private async viewDetails(): Promise<void> {
    // Load the module dynamically
    await import('../../../../../features/directory/preference-cards/preference-cards.module').then(p => p.PreferenceCardsModule);
    const dialogConfig: MatDialogConfig = { ...CreateMediumEntityConfig };
    dialogConfig.data = this.recipe;
    this.dialog.open(PreferenceCardDetailsComponent, dialogConfig);
  }
}
