import { ChangeDetectionStrategy, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { RecipesStoreService } from '../../../services/recipes-store.service';
import { map, Observable, take } from 'rxjs';
import { PhysicianModel, PreferenceCardAssignment } from '@shared/models';
import { MatStepper } from '@angular/material/stepper';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateSmallEntityConfig } from '@constants';
import { AssignmentsFromBomComponent } from '../assignments-from-bom/assignments-from-bom.component';

@Component({
  selector: 'app-create-preference-card',
  templateUrl: './create-preference-card.component.html',
  styleUrls: ['./create-preference-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CreatePreferenceCardComponent implements OnDestroy {
  @ViewChild('stepper') stepperRef: MatStepper;

  assignments: Observable<PreferenceCardAssignment[]> = this.recipesStoreService.store$.pipe(map(st => st.newAssignments));
  newPhysicians$: Observable<PhysicianModel[]> = this.recipesStoreService.store$.pipe(map(st => st.physicians));
  validForm$: Observable<boolean> = this.recipesStoreService.store$.pipe(map(st => st.validForm));

  constructor(
    public recipesStoreService: RecipesStoreService,
    @Inject(MAT_DIALOG_DATA) private data: PreferenceCardAssignment[],
    private dialog: MatDialog
  ) {
    if (this.data?.length) {
      this.recipesStoreService.getProductsForEachAssignment(data);
    }
  }

  getAssignmentsFromBOM(): void {
    const dialogConfig: MatDialogConfig = { ...CreateSmallEntityConfig };

    dialogConfig.height = 'auto';
    this.dialog
      .open(AssignmentsFromBomComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe(assignments => {
        if (assignments?.length) {
          this.recipesStoreService.setNewAssignments(assignments);
        }
      });
  }

  ngOnDestroy() {
    this.recipesStoreService.resetState();
  }
}
