import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { NotificationModel } from '@shared/models';
import { TNotificationsView } from '@shared/type/common-types.type';
import { BehaviorSubject, finalize } from 'rxjs';
import { NotificationsStoreService } from '../../services/notifications-store.service';
import { GlobalStoreService } from '@services/internal/global-store.service';

@Component({
  selector: 'app-widget-notifications',
  templateUrl: './widget-notifications.component.html',
  styleUrls: ['./widget-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class WidgetNotificationsComponent implements OnInit {
  notifications$: BehaviorSubject<NotificationModel[]> = new BehaviorSubject<NotificationModel[]>([]);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectedView: TNotificationsView = 'NOTIFICATIONS';
  notificationsQty: number = 0;

  readonly isMobile$: BehaviorSubject<boolean> = GlobalStoreService.isMobile$;

  constructor(private notificationsStoreService: NotificationsStoreService) {}

  ngOnInit(): void {
    this.getNotifications();
  }

  getNotifications(size: number = 10): void {
    this.loading$.next(true);
    this.notificationsStoreService
      .getNotifications(this.selectedView, size, false)
      .pipe(finalize(() => this.loading$.next(false)))
      .subscribe(data => {
        this.notifications$.next(data.content || []);
        this.notificationsQty = data.totalElements;
      });
  }

  markAllAsRead(): void {
    this.notificationsStoreService.markAllAsRead(this.selectedView, this.notificationsQty);
  }

  view(): void {
    this.notificationsStoreService.viewAll(this.selectedView);
  }
}
