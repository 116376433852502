import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IAnchor } from '@shared/interfaces';
import { ScrollTo } from '@shared/utils/scroll-to';

@Component({
  selector: 'app-info-anchors',
  templateUrl: './info-anchors.component.html',
  styleUrls: ['./info-anchors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class InfoAnchorsComponent {
  @Input() anchors: IAnchor[] = [];

  scrollToAnchor(selector: string): void {
    ScrollTo(selector);
  }
}
