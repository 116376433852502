import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { take } from 'rxjs';

/** pure: false needs to update translations if translation file was loaded after application launched */
@Pipe({
  name: 'language',
  pure: false,
  standalone: false
})
export class LanguagePipe implements PipeTransform {
  private translateService = inject(TranslateService);
  private cache = new Map<TranslationsKeys, string>();

  constructor() {
    this.translateService.onLangChange.pipe(take(1)).subscribe(() => this.cache.clear());
  }

  transform(value: TranslationsKeys, ...args: unknown[]): string {
    if (!value?.length) {
      return '';
    }
    // Save translations and return saved value each time pipe calls, to avoid calling service
    if (this.cache.has(value)) {
      return this.cache.get(value);
    }
    // Fetch translation and cache it
    const translation = this.translateService.instant(value, ...args);
    this.cache.set(value, translation);
    return translation;
  }
}
