import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FacilityService } from '@services/facility.service';
import { PermissionService } from '@services/internal/permission.service';
import { FacilityModel, UserModel } from '@shared/models';
import { BehaviorSubject, finalize, take } from 'rxjs';
import { DisplayName } from '@shared/utils/form-elements/display-with-autocomplete';
import { IFilter } from '@shared/interfaces';
import { FacilitiesPageableParams } from '@shared/models/build-models';
import { FilterUniqueArray } from '@shared/utils/arrays/filter-unique-array';
import { TIcons } from '@shared/type/icons.type';
import { CreateFacilitiesComponent } from 'src/app/features/directory/facilities/partials/modals/create-facilities/create-facilities.component';
import { CreateMediumEntityConfig, DefaultAutoCompleteSize } from '@constants';
import { LanguageService } from '@services/internal/language.service';

@Component({
  selector: 'app-facility-autocomplete',
  templateUrl: './facility-autocomplete.component.html',
  styleUrls: ['./facility-autocomplete.component.scss'],
  standalone: false
})
export class FacilityAutocompleteComponent {
  @Input() facility: FacilityModel = new FacilityModel();
  @Input() multiple: boolean = false;
  @Input() filterView: boolean = false;
  @Input() showAddNewButton: boolean = false;
  @Input() selectedItems: FacilityModel[] = [];
  @Input() removable: boolean = false;
  @Input() required: boolean = false;
  @Input() label: string = LanguageService.instant('shared.components.facility');
  @Input() showOpenInNewIcon: boolean = false;
  @Input() showClearIcon: boolean = false;
  @Input() disabled: boolean = false;
  @Input() invalid: boolean = false;
  @Input() custody: UserModel = new UserModel();
  @Input() representative: UserModel = new UserModel();
  @Input() showInvalidStateOnInit: boolean = false;

  @Output() removeChipEmitter = new EventEmitter<string>();
  @Output() selectedOptionEmitter = new EventEmitter<FacilityModel>();
  @Output() clearInputEmitter = new EventEmitter<void>();
  @Output() valueChangedEmitter = new EventEmitter<string>();
  @Output() assignFacilityEmitter = new EventEmitter<{ custodyId: string; facilityId: string; callBack?: () => void }>();

  permissionService = inject(PermissionService);
  private facilityService = inject(FacilityService);
  private dialog = inject(MatDialog);

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initialLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  searchedData: FacilityModel[] = [];
  forceBlurEvent: boolean = false;

  readonly displayFn = DisplayName;

  searchFacilities(value: string, skipSelectSingleOption: boolean = false, page: number = 0): void {
    this.initialLoading$.next(false);
    this.forceBlurEvent = false;

    if (this.loading$.value) {
      return;
    }

    this.loading$.next(true);

    const params: IFilter = {
      search: value,
      size: DefaultAutoCompleteSize,
      state: 'ACTIVE',
      page,
      sort: 'name,asc'
    };

    if (this.custody?.role === 'SALES' || this.custody?.role === 'DISTRIBUTOR') {
      params.custodyId = this.custody.id;
    }

    const queryParams: FacilitiesPageableParams = new FacilitiesPageableParams(params);

    if (this.multiple || this.permissionService.isRole('SALES')) {
      this.facilityService
        .getPageable(queryParams)
        .pipe(
          take(1),
          finalize(() => this.loading$.next(false))
        )
        .subscribe(data => {
          this.searchedData = FilterUniqueArray(data.content, this.selectedItems);

          if (skipSelectSingleOption && this.searchedData.length === 1) {
            this.forceBlurEvent = true;
            return;
          }

          if (!value && this.searchedData.length === 1) {
            this.selectOption(this.searchedData[0]);
            this.forceBlurEvent = true;
          } else {
            this.forceBlurEvent = false;
          }
          //CC-5432 Load next page if all items selected
          if (this.searchedData?.length === 0 && data?.totalElements > this.selectedItems?.length && page < data.totalPages) {
            setTimeout(() => this.searchFacilities(value, skipSelectSingleOption, page + 1));
          }
        });

      return;
    }

    if (!this.permissionService.isRole('SALES') && this.representative?.id) {
      queryParams.representativeId = this.representative.id;
    }

    if (
      this.permissionService.isRole('ADMIN') ||
      this.permissionService.isRole('DISTRIBUTOR') ||
      this.permissionService.isRole('REGIONAL_MANAGER')
    ) {
      this.facilityService
        .getPageableByRepresentative(queryParams)
        .pipe(
          take(1),
          finalize(() => this.loading$.next(false))
        )
        .subscribe(data => {
          this.searchedData = data.content;

          if (skipSelectSingleOption && this.searchedData.length === 1) {
            this.forceBlurEvent = true;
            return;
          }

          if (!value && this.searchedData.length === 1) {
            this.selectOption(this.searchedData[0]);
            this.forceBlurEvent = true;
          } else {
            this.forceBlurEvent = false;
          }
          //CC-5432 Load next page if all items selected
          if (this.searchedData?.length === 0 && data?.totalElements > this.selectedItems?.length && page < data.totalPages) {
            setTimeout(() => this.searchFacilities(value, skipSelectSingleOption, page + 1));
          }
        });
    }
  }

  doIconAction(icon: TIcons): void {
    if (icon === 'close') {
      this.clearInputEmitter.emit();
      this.searchFacilities('', true);
    }
  }

  async createFacility(): Promise<void> {
    await import('../../../../../features/directory/facilities/facilities.module');
    const dialogConfig: MatDialogConfig = { ...CreateMediumEntityConfig };
    dialogConfig.data = false;

    const dialogRef = this.dialog.open(CreateFacilitiesComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(id => {
        if (!id) {
          return;
        }

        this.facilityService
          .getFacility(id)
          .pipe(take(1))
          .subscribe(facility => {
            this.selectOption(facility);

            if (
              this.permissionService.isRole('ADMIN') &&
              (this.representative?.role === 'SALES' || this.representative?.role === 'DISTRIBUTOR')
            ) {
              const custodyId: string = this.representative.id;
              this.assignFacility(custodyId, id);
            }
          });
      });
  }

  assignFacility(custodyId: string, facilityId: string, callBack?: () => void): void {
    this.assignFacilityEmitter.emit({ custodyId, facilityId, callBack });
  }

  removeFacilityChip(facilityId: string): void {
    this.removeChipEmitter.emit(facilityId);
  }

  selectOption(facility: FacilityModel): void {
    this.selectedOptionEmitter.emit(facility);
    if (this.multiple) {
      //Hide selected item from search results
      this.searchedData = this.searchedData.filter(c => c.id !== facility.id);
    }
  }
}
