import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { BehaviorSubject, finalize, take } from 'rxjs';
import { LocationService } from '@services/location.service';
import { LocationCoordsModel, LocationModel, UserModel } from '@shared/models';
import { DisplayName } from '@shared/utils/form-elements/display-with-autocomplete';
import { TrackById } from '@shared/utils/form-elements/track-by';
import { TIcons } from '@shared/type/icons.type';
import { LocationsPageableParams } from '@shared/models/build-models';
import { PermissionService } from '@services/internal/permission.service';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateSmallEntityConfig, DefaultAutoCompleteSize } from '@constants';
import { CreateLocationComponent } from 'src/app/features/movement/location/partials/modals/create-location/create-location.component';
import { ModalMapViewComponent } from '@shared/modules/maps/partials/modal-map-view/modal-map-view.component';
import { IFilter } from '@shared/interfaces';

@Component({
  selector: 'app-locations-autocomplete',
  templateUrl: './locations-autocomplete.component.html',
  styleUrls: ['./locations-autocomplete.component.scss'],
  standalone: false
})
export class LocationsAutocompleteComponent {
  @Input('location') set locationData(data: LocationModel) {
    if (!data?.id) {
      return;
    }

    this.inactiveState = data.state !== 'ACTIVE';
    this.location = data;
  }
  @Input() showAddNew: boolean = false;
  @Input() filterView: boolean = false;
  @Input() showMapViewIcon: boolean = false;
  @Input() showViewAll: boolean = false;
  @Input() disabled: boolean = false;
  @Input() borderTransparent: boolean = false;
  @Input() required: boolean = false;
  @Input() showClearIcon: boolean = true;
  @Input() tooltip: TranslationsKeys;
  @Input() user: UserModel = new UserModel();

  @Output() optionSelectedEmitter = new EventEmitter<LocationModel>();
  @Output() clearInputEmitter = new EventEmitter<void>();
  @Output() showMapEmitter = new EventEmitter<LocationCoordsModel>();
  @Output() valueChangedEmitter = new EventEmitter<string>();

  permissionService = inject(PermissionService);
  private dialog = inject(MatDialog);
  private locationService = inject(LocationService);

  searchedData$: BehaviorSubject<LocationModel[]> = new BehaviorSubject<LocationModel[]>([]);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initialLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  location: LocationModel = new LocationModel();
  inactiveState: boolean = false;
  forceBlurEvent: boolean = false;

  readonly displayFn = DisplayName;
  readonly trackBy = TrackById;

  searchLocations(name: string, skipSelectSingleOption: boolean = false): void {
    this.initialLoading$.next(false);
    this.forceBlurEvent = false;

    if (this.loading$.value) {
      return;
    }

    this.loading$.next(true);
    this.searchedData$.next([]);
    const filterParams: IFilter = { size: DefaultAutoCompleteSize, state: 'ACTIVE', sort: 'name,asc' };
    const params: LocationsPageableParams = new LocationsPageableParams(filterParams);

    if (name) {
      params.searchText = name;
    }

    if (this.user?.id) {
      params.userId = this.user.id;
    }

    this.locationService
      .getPageable(params)
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(data => {
        this.searchedData$.next(data.content);

        if (skipSelectSingleOption && this.searchedData$.value.length === 1) {
          this.forceBlurEvent = true;
          return;
        }

        if (!name && this.searchedData$.value.length === 1) {
          this.selectOption(this.searchedData$.value[0]);
          this.forceBlurEvent = true;
        } else {
          this.forceBlurEvent = false;
        }
      });
  }

  showLocationOnMap(event: Event, location: LocationModel): void {
    const e: Event = event || window.event;

    e.preventDefault();
    e.stopPropagation();

    const convertedModel: LocationCoordsModel = { ...location, latitude: Number(location.latitude), longitude: Number(location.longitude) };
    const dialogConfig: MatDialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.width = '40%';
    dialogConfig.maxWidth = '750px';
    dialogConfig.panelClass = 'largeModalWindow';
    dialogConfig.data = convertedModel;
    dialogConfig.autoFocus = false;
    this.dialog.open(ModalMapViewComponent, dialogConfig);
  }

  selectOption(location: LocationModel): void {
    this.optionSelectedEmitter.emit(location);
  }

  doIconAction(icon: TIcons): void {
    if (icon === 'close') {
      this.clearInputEmitter.emit();
      this.searchLocations('', true);
    } else if (icon === 'location') {
      this.showLocationOnMap(null, this.location);
    }
  }

  createLocation(): void {
    const dialogConfig: MatDialogConfig = { ...CreateSmallEntityConfig };

    dialogConfig.height = 'auto';
    this.dialog
      .open(CreateLocationComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe(id => {
        if (!id) {
          return;
        }

        this.locationService
          .getLocation(id)
          .pipe(take(1))
          .subscribe(location => {
            this.selectOption(location);
          });
      });
  }
}
