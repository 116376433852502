import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotificationModel } from '@shared/models';
import { BehaviorSubject, finalize } from 'rxjs';
import { NotificationsStoreService } from '../../../services/notifications-store.service';
import { TNotificationsView } from '@shared/type/common-types.type';

@Component({
  selector: 'app-modal-notifications',
  templateUrl: './modal-notifications.component.html',
  styleUrls: ['./modal-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ModalNotificationsComponent implements OnInit {
  notifications$: BehaviorSubject<NotificationModel[]> = new BehaviorSubject<NotificationModel[]>([]);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectedView: TNotificationsView = 'NOTIFICATIONS';
  notificationsQty: number = 0;

  constructor(
    private dialogRef: MatDialogRef<ModalNotificationsComponent>,
    private router: Router,
    private notificationsStoreService: NotificationsStoreService
  ) {}

  ngOnInit() {
    this.getNotifications();
  }

  markAllAsRead(): void {
    this.close();
    this.notificationsStoreService.markAllAsRead(this.selectedView, this.notificationsQty);
  }

  isActive = (url: string): boolean => this.router.url.includes(url);

  close(): void {
    this.dialogRef.close();
  }

  view(): void {
    this.close();
    this.notificationsStoreService.viewAll(this.selectedView);
  }

  getNotifications(size?: number): void {
    this.loading$.next(true);
    this.notificationsStoreService
      .getNotifications(this.selectedView, size)
      .pipe(finalize(() => this.loading$.next(false)))
      .subscribe(data => {
        this.notifications$.next(data.content || []);
        this.notificationsQty = data.totalElements;
      });
  }
}
