import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Optional, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PhysiciansService } from '@services/physicians.service';
import { Router } from '@angular/router';
import { CanAutoSaveDirective } from '@guards/autosave/can-auto-save';
import { FacilityModel, ProcedureModel, ProductLineModel } from '@shared/models';
import { PhysicianRequestModel } from '@shared/models/request-models/physician-request-model';
import { BehaviorSubject, finalize, Observable, take } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-physician',
  templateUrl: './create-physician.component.html',
  styleUrls: ['./create-physician.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CreatePhysicianComponent extends CanAutoSaveDirective {
  @ViewChild('stepper') stepperRef: MatStepper;

  formAssignments: UntypedFormGroup;
  formContacts: UntypedFormGroup;
  formName: UntypedFormGroup;
  formsValid: boolean;
  formsTouched$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  searching: boolean;
  selectedFacilities: FacilityModel[] = [];
  selectedProcedures: ProcedureModel[] = [];
  selectedProducts: ProductLineModel[] = [];
  tags: { tagText: string }[] = [];
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public physiciansService: PhysiciansService,
    public router: Router,
    public ref: ChangeDetectorRef,
    @Optional() public dialogRef: MatDialogRef<CreatePhysicianComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public redirect: boolean
  ) {
    super();
  }

  autoSave(): void {
    this.submitWithSubscription(false);
  }

  autoSaveFormsCheckValid(): UntypedFormGroup[] {
    return [this.formName, this.formContacts, this.formAssignments];
  }

  addTags(value: string): void {
    if ((value || '').trim()) {
      this.tags.push({ tagText: value.trim() });
    }
    this.ref.markForCheck();
  }

  removeTags(item: { tagText: string }): void {
    const index: number = this.tags.indexOf(item);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  checkValidation(): void {
    setTimeout(() => {
      this.formsValid = this.formName.valid && this.formContacts.valid && this.formAssignments.valid;
      this.formsTouched$.next(this.formName.touched || this.formContacts.touched || this.formAssignments.touched);
      this.ref.markForCheck();
    }, 50);
  }

  removeItemEmitter(event: { id: string; type: string }): void {
    switch (event.type) {
      case 'PROCEDURE': {
        this.selectedProcedures = this.selectedProcedures.filter(el => el.id !== event.id);
        break;
      }
      case 'FACILITY': {
        this.selectedFacilities = this.selectedFacilities.filter(el => el.id !== event.id);
        break;
      }
      case 'PRODUCT_LINE': {
        this.selectedProducts = this.selectedProducts.filter(el => el.id !== event.id);
        break;
      }
    }
  }

  selectItemEmitter(event: { type: 'PROCEDURE' | 'FACILITY' | 'PRODUCT_LINE'; value: any }): void {
    switch (event.type) {
      case 'PROCEDURE': {
        this.selectedProcedures.push(event.value);
        break;
      }
      case 'FACILITY': {
        this.selectedFacilities.push(event.value);
        break;
      }
      case 'PRODUCT_LINE': {
        this.selectedProducts.push(event.value);
        break;
      }
    }
  }

  submit(redirectToDetailPage: boolean = true): Observable<string> {
    this.loading$.next(true);

    return new Observable(observer => {
      const facilityIds = this.selectedFacilities.map(x => x.id);
      const procedureIds = this.selectedProcedures.map(x => x.id);
      const productIds = this.selectedProducts.map(x => x.id);
      const result: PhysicianRequestModel = {
        ...this.formName.value,
        ...this.formContacts.value,
        facilityIds,
        procedureIds,
        productIds,
        ...{ notes: [] },
        tags: this.tags.map(t => t.tagText) || []
      };

      this.physiciansService
        .createPhysician(result)
        .pipe(
          take(1),
          finalize(() => this.loading$.next(false))
        )
        .subscribe((id: string) => {
          if (redirectToDetailPage) {
            this.router.navigate([`/directory/physicians/edit/${id}`]).then();
          }

          this.formName.reset();
          this.formContacts.reset();
          this.formAssignments.reset();
          this.tags = [];
          observer.next(id);
        });
    });
  }

  submitWithSubscription(redirectToDetailPage: boolean = true) {
    this.submit(redirectToDetailPage).pipe(take(1)).subscribe();
  }

  submitWithoutRedirect(): void {
    this.submit(false)
      .pipe(take(1))
      .subscribe((id: string) => this.dialogRef.close(id));
  }
}
