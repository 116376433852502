import { Injectable } from '@angular/core';
import { ApiService } from '@shared/classes/api-service';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UniqueId } from '@shared/models/shared/shared-kernel';
import { UserModel } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class UserCustodianOnlyService extends ApiService {
  getAssignedCustodianOnlyUser(userId: UniqueId, custodianName: string = ''): Observable<UserModel[]> {
    return this.get<UserModel[]>(`users/${userId}/custodians-only?custodianName=${custodianName}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  addAssignedCustodianOnlyUser(userId: UniqueId, params: { custodianOnlyIds: UniqueId[] }): Observable<UniqueId[]> {
    return this.post<UniqueId[]>(`users/${userId}/custodians-only`, params).pipe(
      map((data: UniqueId[]) => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return data;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  unAssignCustodianOnlyUser(userId: UniqueId, body: UniqueId[]): Observable<void> {
    return this.delete<void>(`users/${userId}/custodians-only`, {}, false, body).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.saved')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  isCustodianOnlyHasQuickTransferConnection(custodianOnlyId: UniqueId): Observable<boolean> {
    return this.get<boolean>(`users/custodians-only/${custodianOnlyId}/exists`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }
}
