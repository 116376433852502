import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { SimilarInventoryInfo } from '@shared/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-processing-modal',
  templateUrl: './processing-modal.component.html',
  styleUrls: ['./processing-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ProcessingModalComponent {
  readonly isMobile$: BehaviorSubject<boolean> = GlobalStoreService.isMobile$;

  constructor(
    private dialogRef: MatDialogRef<ProcessingModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      searchValue: string;
      loading: Observable<boolean>;
      matchedInventory: BehaviorSubject<SimilarInventoryInfo>;
    }
  ) {
    this.data.matchedInventory.pipe(takeUntilDestroyed()).subscribe(inventory => {
      if (inventory) {
        this.close(false);
      }
    });
  }

  close(clearFilter: boolean): void {
    this.dialogRef.close(clearFilter);

    if (!clearFilter) {
      setTimeout(() => {
        const children = document.querySelectorAll('[data-cy="scanToAdd"]');
        (children[children.length - 1] as HTMLElement).click();
      });
    }
  }
}
