import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LanguageService } from '@services/internal/language.service';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  private snackBar = inject(MatSnackBar);

  dismiss(): void {
    this.snackBar.dismiss();
  }

  showError(keyToTranslate: TranslationsKeys = null, backendErrorMessage: string = null, time: number = 10000): void {
    if (keyToTranslate || backendErrorMessage) {
      const msg = backendErrorMessage ? backendErrorMessage : LanguageService.instant(keyToTranslate);
      this.snackBar.open(msg, '×', {
        duration: time,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: ['snack-error']
      });
    }
  }

  showSuccess(
    keyToTranslate: TranslationsKeys = null,
    message: string = null,
    time: number = 5000,
    actionButtonText: string = '×',
    callback?: () => void
  ): void {
    //Do not use GlobalStoreService to avoid compile errors
    if (window.innerWidth < 1279) {
      time = 1000;
    }

    if (keyToTranslate || message) {
      const msg = message ? message : LanguageService.instant(keyToTranslate);
      const snackRef = this.snackBar.open(msg, actionButtonText, {
        duration: time,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: ['snack-success', actionButtonText !== '×' ? 'actionButton' : null]
      });

      if (callback) {
        snackRef
          .onAction()
          .pipe(take(1))
          .subscribe(() => callback());
      }
    }
  }

  showWarning(keyToTranslate: TranslationsKeys = null, time: number = 5000): void {
    if (keyToTranslate) {
      this.snackBar.open(LanguageService.instant(keyToTranslate), '×', {
        duration: time,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: ['snack-warning']
      });
    }
  }
}
