@if (allowRender()) {
  @if (platform === 'web') {
    <router-outlet />
  } @else {
    <div class="refresh-container" (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd()">
      @if (pullDistance() > 0) {
        <div class="refresh-icon">
          <mat-progress-spinner [value]="(pullDistance() * 100) / minPullDistance()" mode="determinate" diameter="30" />
        </div>
      }
      <router-outlet />
    </div>
  }
} @else {
  <div class="devGuardBg">
    <app-input-kit
      [dataCy]="'welcomePassword'"
      [required]="true"
      [type]="'password'"
      [initialValue]="password()"
      [label]="'Password'"
      (valueChangedEmitter)="password.set($event)"
      (enterEmitter)="checkPassword()"
    />
  </div>
}
