import { PermissionModel } from '@shared/models';
import { MergeModels } from '../../merge-models';
import { ECompanyTypes } from '@shared/enum';

export class ManufacturerAdminRole {
  model: PermissionModel = {
    config: {
      companyType: ECompanyTypes.MANUFACTURER,
      roleName: 'ADMIN',
      validRole: true,
      permissionsWasSet: true
    },
    common: {
      visitDetailPageAssignedEntity: true,
      addComment: true
    },
    dashboard: {
      seeTrackingActionsWidget: true,
      seeSalesPerEventWidget: true,
      filterByRepresentative: true,
      readTotalSalesWidget: true,
      readExpirationsByCustodyWidget: true,
      readTopUsedCatalogsWidget: true,
      readSalesTargetWidget: true,
      readInventoryExtensionRequestWidget: true
    },
    events: {
      canGenerateInvoice: true,
      canCloseEvent: true,
      canSignEvent: true,
      canAddPONumber: true,
      canChangeEventStatus: true,
      canScheduleEvent: true,
      canEditEvent: true,
      canMarkAsPaid: true,
      canChangeCommissionAndInvoicePaid: true,
      canExportSalesOrderFormToQuickBooks: true,
      canReadUsers: true,
      canReadDUTPriceHasChangedByNonAdmin: true,
      canReadStockAdjustment: true,
      toggleCalendarView: true,
      filterEvents: true,
      createEvent: true,
      seeImages: true,
      editPriceAdjustments: true,
      readTotalValue: true,
      signEventUsingCanvas: true,
      deviceUsedBulkActions: true,
      submitForBillingRequestReplenishment: true,
      useAdditionalFiltersOnMUI: true,
      copyEvent: true,
      editPONUmber: true
    },
    report: {
      readReport: true,
      readCaseReport: true,
      readParLevelReport: true,
      readContainerTurnReport: true,
      readTransferReport: true,
      inventoryReport: true,
      readTotalSalesReport: true,
      readCustomSalesReport: true,
      usersReport: true,
      productLinesReport: true,
      catalogsReport: true,
      facilitiesReport: true,
      physiciansReport: true,
      inventoryRequestReport: true,
      exportInventory: true,
      readInventoryReorderReport: true,
      directoryGroupReports: true,
      movementsGroupReports: true,
      inventoryAging: true,
      weightedAverageCost: true,
      facilityStock: true
    },
    forbiddenRoutes: [],
    user: {
      readUserCommission: true,
      addUsersToSystem: true,
      canEditShippingAddress: true,
      canReadUsers: true,
      canDeactivateUsers: true,
      changeUserRole: true,
      readUsersStatistics: true,
      filterUsersByRole: true,
      sendPOReminder: true,
      canCreateCommissionOverride: true,
      requestAdditionalSeats: true,
      unLockUser: true,
      toggleCustodianOnly: true,
      organizationTree: true
    },
    settings: {
      writeStartInvoiceNumber: true,
      editOrgAccountInfo: true,
      editSelfIndividualAccountInfo: true,
      readIntegrations: true,
      canEditEmailDigest: true,
      sendWeeklyPOReminder: true,
      editCustomPermissions: true,
      canEditBilledManufacturers: true
    },
    exportTask: {
      readExportTasks: true
    },
    inventory: {
      scanViaUDIdentify: true,
      canCreateProductLine: true,
      canCreateCatalog: true,
      canCreateInventoryImport: true,
      createInventory: true,
      createInventoryTransfer: true,
      canEditInventoryAdditionalInfo: true,
      canEditInventoryPriceInfo: true,
      canMakeTransferFromIndividualToOrganization: true,
      canFilterDevicesByCustody: true,
      createInventoryTransferFromAnyone: true,
      approveAnyTransfer: true,
      acceptSelfTransferOnly: true,
      canInitiateInventoryRequest: true,
      canFulfillInventoryRequest: true,
      canMarkInventoryAsUsedInEvent: true,
      canEditPriceOfInventoryInDUT: true,
      canAdd_IFY_ToProductLine: true,
      canFilterInventoriesByCustody: true,
      canReadInventoryImport: true,
      canEditProductLine: true,
      canEditCatalog: true,
      canReadParLevel: true,
      canReadCOG: true,
      canFilterInventoriesByHoldStatus: true,
      canManageInventoryAtRequestDetailPage: true,
      readTransferShippingCosts: true,
      filterTransfersBySender: true,
      filterTransferByRecipient: true,
      manageHoldStatus: true,
      assignEventToTransfer: true,
      setDueBackDateInTransfer: true,
      readFieldTransferFlag: true,
      readInventoryValue: true,
      filterInventories: true,
      readCatalogDetail: true,
      readProductLineDetail: true,
      readImportDetail: true,
      readEventDetail: true,
      markDeviceInEvent: true,
      createBOM: true,
      createAudits: true,
      manageInventoryRequestInProgress: true
    },
    movements: {
      createLocation: true,
      createRequestRecipe: true,
      filterProductsByRequester: true
    },
    directory: {
      createFacility: true,
      createPhysician: true,
      createProcedure: true,
      createManufacturer: true,
      deactivateManufacturer: true,
      editFacility: true,
      editPhysician: true,
      editProcedure: true,
      editManufacturer: true,
      canAddNotesToFacility: true,
      canAddNotesToPhysician: true,
      canAddNotesToProcedure: true,
      canUploadFacilityPrice: true,
      canReadCustomerIdAtFacilityPage: true,
      markFacilityContract: true,
      readRegions: true,
      readFacilityDetail: true,
      readPhysicianDetail: true,
      readProcedureDetail: true,
      readManufacturerDetail: true,
      readPriceSheets: true,
      managePriceSheets: true,
      editCreditHold: true
    }
  };

  constructor() {
    this.model = MergeModels(this.model);
  }
}
