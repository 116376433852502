import { IFilter } from '@shared/interfaces';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { TItemType, TState } from '@shared/type/index.type';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';

export class CatalogsPageableParams {
  manufacturerId?: string;
  page: number;
  productId?: string;
  referenceNumber?: string;
  size: number;
  sort: string[];
  state?: TState;
  belowParLevel?: boolean;
  //Show catalogs without assigned inventories
  backOrder?: boolean;
  lowStock?: boolean;
  hasParLevel?: boolean;
  inStock?: boolean;
  searchText?: string;
  productIds?: string[];
  manufacturerIds?: string[];
  catalogType?: TItemType;
  catalogTypes?: TItemType[];
  isConnectedToPack?: boolean;
  joinProduct?: true;

  constructor(params: IFilter) {
    return CatalogsPageableParams.build(params);
  }

  private static build(params: IFilter): CatalogsPageableParams {
    const queryParams: CatalogsPageableParams = DefaultQueryParams(params);
    queryParams.productId = params.productId;
    queryParams.searchText = params.search;

    if (!params.withoutState) {
      queryParams.state = params?.state || 'ACTIVE';
    }
    if (params.manufacturer && typeof params.manufacturer !== 'boolean') {
      queryParams.manufacturerId = params.manufacturer;
    }
    if (params.belowParLevel) {
      queryParams.belowParLevel = params.belowParLevel;
    }
    if (params.backOrder) {
      queryParams.backOrder = params.backOrder;
    }
    if (params.lowStock) {
      queryParams.lowStock = params.lowStock;
    }
    if (params.inStock) {
      queryParams.inStock = params.inStock;
    }
    if (params.hasOwnProperty('hasParLevel')) {
      queryParams.hasParLevel = params.hasParLevel;
    }
    if (params?.productIds?.length) {
      queryParams.productIds = params.productIds;
    }
    if (params?.manufacturerIds?.length) {
      queryParams.manufacturerIds = params.manufacturerIds;
    }
    if (params?.itemType) {
      queryParams.catalogType = params.itemType;
    }
    if (typeof params?.isConnectedToPack === 'boolean') {
      queryParams.isConnectedToPack = params.isConnectedToPack;
    }
    if (params?.inventoryTypes?.length && params?.inventoryTypes[0] !== undefined) {
      queryParams.catalogTypes = params.inventoryTypes;
    }
    if (params?.joinProduct) {
      queryParams.joinProduct = params?.joinProduct;
    }
    return ClearRequest(queryParams);
  }
}
