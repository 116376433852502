import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { TransfersService } from '@services/transfers.service';
import { InventoryModel, UserModel } from '@shared/models';
import { InventoryPageableParams } from '@shared/models/build-models';
import { BehaviorSubject, finalize, take } from 'rxjs';
import { DisplayName } from '@shared/utils/form-elements/display-with-autocomplete';
import { TrackById } from '@shared/utils/form-elements/track-by';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';
import { FilterUniqueArray } from '@shared/utils/arrays/filter-unique-array';

@Component({
  selector: 'app-assign-transferred-inventory-to-container',
  templateUrl: './assign-transferred-inventory-to-container.component.html',
  styleUrls: ['./assign-transferred-inventory-to-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AssignTransferInventoryToContainerComponent {
  @Input() recipient: UserModel = new UserModel();
  @Input() selectedContainer: InventoryModel = new InventoryModel();

  @Output() chooseContainerEmitter = new EventEmitter<InventoryModel>();
  @Output() closeMatMenuEmitter = new EventEmitter<void>();

  private transfersService = inject(TransfersService);

  containers$: BehaviorSubject<InventoryModel[]> = new BehaviorSubject<InventoryModel[]>([]);
  searching$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly displayFn = DisplayName;
  readonly trackBy = TrackById;

  searContainer(searchText: string): void {
    if (this.searching$.value) {
      return;
    }
    this.searching$.next(true);
    this.containers$.next([]);
    const queryParams: InventoryPageableParams = {
      searchText,
      size: 20
    };
    if (this.recipient?.id) {
      queryParams.custodyId = this.recipient.id;
    } else {
      queryParams.inOrgCustody = true;
    }
    this.transfersService
      .getAvailableContainers(ClearRequest(queryParams))
      .pipe(
        take(1),
        finalize(() => this.searching$.next(false))
      )
      .subscribe(data => {
        const selectedCont = this.selectedContainer ? [this.selectedContainer] : [];
        this.containers$.next(FilterUniqueArray(data.content, selectedCont));
      });
  }
}
