import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { TagsService } from '@services/tags.service';
import { BehaviorSubject, finalize, take } from 'rxjs';
import { TTagType, TIcons } from '@shared/type/index.type';
import { ITag } from '@shared/interfaces/tag';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { AlertsService } from '@services/internal/alerts.service';
import { DefaultAutoCompleteSize } from '@constants';

@Component({
  selector: 'app-tags-autocomplete',
  templateUrl: './tags-autocomplete.component.html',
  styleUrls: ['./tags-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class TagsAutocompleteComponent {
  @Input() selectedChips: { tagText: string }[];
  @Input() tagEntityType: TTagType;
  @Input() disabled: boolean = false;
  @Input() removable: boolean = true;
  @Input() tooltipText: TranslationsKeys;
  @Input() icons: TIcons[] = [];
  @Input() label: TranslationsKeys = 'shared.labels.searchTagsLabel';

  @Output() removeChipEmitter = new EventEmitter<any>();
  @Output() selectedOptionEmitter = new EventEmitter<string>();

  private tagsService = inject(TagsService);
  private alertsService = inject(AlertsService);

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchedData$: BehaviorSubject<ITag[]> = new BehaviorSubject<ITag[]>([]);
  clearInput: boolean = true;
  waitToBeAdded: boolean = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  searchTags(value: string, page: number = 0): void {
    this.loading$.next(true);
    this.searchedData$.next([]);

    this.tagsService
      .searchTags({ entityType: this.tagEntityType, tagName: value, size: DefaultAutoCompleteSize, page })
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(pageable => {
        const content = pageable.content;
        const itemsToExclude = new Set(this.selectedChips.map(item => item.tagText));
        const excludedItems = content.filter(item => !itemsToExclude.has(item.tagText));
        this.searchedData$.next(excludedItems);
        //CC-5432 Load next page if all items selected
        if (this.searchedData$.value?.length === 0 && pageable?.totalElements > this.selectedChips?.length && page < pageable.totalPages) {
          setTimeout(() => this.searchTags(value, page + 1));
        }
      });
  }

  selectTag(tagText: string, preventDoubleSelect: boolean = false): void {
    if (this.selectedChips.some(t => t.tagText === tagText)) {
      this.alertsService.showError('shared.alerts.errorMessages.tagDuplication', null, 5000);
      return;
    }

    /** Do not emmit en event if it triggers on blur and the option was selected from autocomplete*/
    this.waitToBeAdded = false;
    if (preventDoubleSelect) {
      setTimeout(() => {
        if (!this.waitToBeAdded) {
          if (tagText?.length) {
            this.selectedOptionEmitter.emit(tagText);
          }
          this.waitToBeAdded = false;
        }
      }, 500);
    } else {
      this.waitToBeAdded = true;
      this.selectedOptionEmitter.emit(tagText);
    }
    //Hide selected item from search results
    this.searchedData$.next(this.searchedData$.value.filter(c => c.tagText !== tagText));
  }

  removeChip(tagText: { tagText: string }): void {
    this.removeChipEmitter.emit(tagText);
  }
}
