import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PONumberModel } from '@shared/models';
import { TrackById } from '@shared/utils/form-elements/track-by';
import { set } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { UsersService } from '@services/users.service';
import { IAddPONumberDTO, PONumberManufacturerDTO } from '@shared/interfaces/events/po-number-dto';

@Component({
  selector: 'app-po-number',
  templateUrl: './po-number.component.html',
  styleUrls: ['./po-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PoNumberComponent implements OnInit {
  commonPONumber: string = '';
  poDate: Date;
  poNumbersFulFilled: boolean = false;
  readonly trackBy = TrackById;

  constructor(
    private dialogRef: MatDialogRef<PoNumberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { eventId: string; poNumbers: PONumberModel[] }
  ) {}

  checkEmpty() {
    this.poNumbersFulFilled = true;
    this.data.poNumbers.every((manufacturer): boolean | void => {
      if (!manufacturer.poNumber || !manufacturer.poNumber.length) {
        this.poNumbersFulFilled = false;
      } else {
        return true;
      }
    });
    if (this.data.poNumbers.length && this.data.poNumbers[0].poDate && !this.poDate) {
      this.poDate = new Date(this.data.poNumbers[0].poDate);
    }
  }

  close(res?: IAddPONumberDTO): void {
    this.dialogRef.close(res);
  }

  ngOnInit(): void {
    this.checkEmpty();
  }

  savePONumber(): void {
    // Get local machine timeZone (datepicker always looks on local timeZone)
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const datetime: number = zonedTimeToUtc(this.poDate, timezone).getTime();
    const poDate: string = set(datetime, { hours: 12, minutes: 0 }).toISOString();
    const currentUserId = UsersService.getUser().id;

    const poNumbers: PONumberManufacturerDTO[] = this.data.poNumbers.map(manufacture => ({
      manufacturerId: manufacture.manufacturer.id,
      poNumber: manufacture.poNumber,
      poDate,
      createdByUserId: currentUserId
    }));

    const params: IAddPONumberDTO = {
      poNumberManufacturerDTOS: poNumbers
    };

    this.close(params);
  }

  setPoNumbersForAllManufactures(): void {
    this.data.poNumbers.forEach(manufacture => {
      manufacture.poNumber = this.commonPONumber;
    });
  }
}
