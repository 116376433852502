import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { AuthenticationService } from '@services/auth.service';
import { LocalStorage } from '@services/internal/localstorage.service';
import { ISwitchOrganization, IUserOrganizationDto } from '@shared/interfaces';
import { firstValueFrom } from 'rxjs';
import { NativeBiometric } from 'capacitor-native-biometric';
import { environment } from '@environment';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { PushNotificationsService } from '@services/push-notifications.service';
import { Wait } from '@shared/utils/wait';
declare let pendo: any;

@Component({
  selector: 'app-mobile-organizations-list',
  templateUrl: './mobile-organizations-list.component.html',
  styleUrls: ['./mobile-organizations-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class MobileOrganizationsListComponent {
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { organizations: IUserOrganizationDto[]; currentOrgId: string },
    private bottomSheetRef: MatBottomSheetRef<MobileOrganizationsListComponent>,
    private authenticationService: AuthenticationService,
    private router: Router,
    private pushNotificationsService: PushNotificationsService
  ) {}

  cancel(): void {
    this.bottomSheetRef.dismiss();
  }

  async switchOrg(organizationId: string): Promise<void> {
    if (organizationId === this.data.currentOrgId) {
      this.cancel();
      return;
    }
    const tokens: ISwitchOrganization = {
      accessToken: AuthenticationService.getToken(),
      organizationId
    };
    const switchedOrg: boolean = await firstValueFrom(this.authenticationService.switchOrganization(tokens));
    if (switchedOrg) {
      // On change org delete previous token and register new
      if (GlobalStoreService.getPlatform() !== 'web') {
        await Wait();
        await firstValueFrom(this.pushNotificationsService.unSubscribe());
        await this.authenticationService.refreshToken();
        // Register push notifications with a new token
        await firstValueFrom(this.pushNotificationsService.subscribe(this.pushNotificationsService.apnsToken$.value));
        //Delete previous Face id credentials for previous organization
        await NativeBiometric.deleteCredentials({ server: environment.frontUrl });
      }
      await this.router.navigate(['/dashboard']);
      LocalStorage.removeItem('dashboard');
      if (typeof pendo?.clearSession === 'function') {
        pendo.clearSession();
      }
      document.location.reload();
    }
  }
}
