import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { PhysicianModel, UserModel } from '@shared/models';
import { TPhoneFormat } from '@shared/type/index.type';
import { EPhoneFormat } from '@shared/enum';
import { checkPhoneFormat } from '@shared/utils/phone/check-phone-format';
import { EmailPattern } from '@shared/validators/email-validator';
import { UsersService } from '@services/users.service';

@Component({
  selector: 'app-form-contacts',
  templateUrl: './form-contacts.component.html',
  styleUrls: ['./form-contacts.component.scss'],
  standalone: false
})
export class FormContactsComponent extends DestroySubscriptions implements OnInit {
  @Input() set physician(value: PhysicianModel) {
    this.setValue(value);
  }
  @Input() createEntityFlow: boolean = false;

  @Output() formNameValueChangeEmitter = new EventEmitter<UntypedFormGroup>();

  formContacts: UntypedFormGroup;
  primaryPhoneFormat: TPhoneFormat = 'US';
  secondaryPhoneFormat: TPhoneFormat = 'US';

  readonly currentUser: UserModel = UsersService.getUser();
  readonly phoneFormat: Record<string, string> = EPhoneFormat;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private ref: ChangeDetectorRef
  ) {
    super();
  }

  checkPrimaryPhoneFormat(): void {
    checkPhoneFormat(this.formContacts, this.primaryPhoneFormat, 'primaryPhone');
  }

  checkSecondaryPhoneFormat(): void {
    checkPhoneFormat(this.formContacts, this.secondaryPhoneFormat, 'secondaryPhone');
  }

  ngOnInit(): void {
    this.createForm();
    this.formChanges();
    this.checkPrimaryPhoneFormat();
    this.checkSecondaryPhoneFormat();
  }

  private createForm(): void {
    this.formContacts = this.formBuilder.group({
      city: ['', [Validators.required, Validators.maxLength(255)]],
      addressState: ['', [Validators.required, Validators.maxLength(255)]],
      zipCode: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      primaryLocation: ['', [Validators.maxLength(255)]],
      primaryPhone: ['', [Validators.pattern('^[0-9]*$'), Validators.maxLength(255)]],
      secondaryPhone: ['', [Validators.pattern('^[0-9]*$'), Validators.maxLength(255)]],
      email: ['', [Validators.pattern(EmailPattern), Validators.maxLength(255)]],
      npi: ['', [Validators.maxLength(255)]],
      externalId: ['', [Validators.maxLength(255)]]
    });
  }

  private formChanges(): void {
    setTimeout(() => {
      this.formNameValueChangeEmitter.emit(this.formContacts);
    });
    this.formContacts.valueChanges.pipe(takeUntil(this.subscriptions)).subscribe(() => {
      this.formNameValueChangeEmitter.emit(this.formContacts);
    });
  }

  private setValue(data: PhysicianModel): void {
    if (!data.firstName) {
      return;
    }
    this.formContacts.setValue({
      city: data.city,
      addressState: data.addressState,
      zipCode: data.zipCode,
      primaryLocation: data.primaryLocation,
      primaryPhone: data.primaryPhone,
      secondaryPhone: data.secondaryPhone,
      email: data.email,
      npi: data.npi,
      externalId: data.externalId
    });
    this.primaryPhoneFormat = data.primaryPhone?.length >= 11 ? 'INTERNATIONAL' : 'US';
    this.secondaryPhoneFormat = data.secondaryPhone?.length >= 11 ? 'INTERNATIONAL' : 'US';
    this.checkPrimaryPhoneFormat();
    this.checkSecondaryPhoneFormat();
    setTimeout(() => {
      this.ref.markForCheck();
    });
  }
}
