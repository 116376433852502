import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CatalogParLevelDTOModel, ContactModel, FacilityModel, ImageModel, PageableModel, PricingFacilityModel } from '@shared/models';
import { HttpHelperService } from './internal/http-helper.service';
import { FacilityParLevelRequestModel } from '@shared/models/request-models';
import { FacilitiesPageableParams } from '@shared/models/build-models';
import { IUploadFacilityPrice } from '@shared/interfaces';
import { Observable, of, catchError, map } from 'rxjs';
import { AuthenticationService } from './auth.service';
import { ApiService } from '@shared/classes/api-service';
import { environment } from '@environment';
import { TFacilityGeographicalType } from '@shared/type/index.type';

@Injectable({
  providedIn: 'root'
})
export class FacilityService extends ApiService {
  deactivate(facilityId: string): Observable<void> {
    return this.put<void>(`facilities/${facilityId}/deactivate`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.deactivated');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getFacility(id: string, redirectToNotFoundPage: boolean = false): Observable<FacilityModel> {
    return this.get<FacilityModel>(`facilities/${id}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        HttpHelperService.errorHandler(error, null, redirectToNotFoundPage);
        return of(null);
      })
    );
  }

  getFacilityContacts = (id: string): Observable<ContactModel[]> =>
    this.get<ContactModel[]>(`facilities/${id}/contacts`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.loadFacilityContacts', error.message);
        return of([]);
      })
    );

  getFacilityParLevel(facilityId: string): Observable<CatalogParLevelDTOModel[]> {
    return this.get<CatalogParLevelDTOModel[]>(`facilities/${facilityId}/catalog`);
  }

  getFacilityPrices(id: string, manufacturerId: string): Observable<PricingFacilityModel[]> {
    return this.get<PricingFacilityModel[]>(`facilities/${id}/${manufacturerId}/pricing`).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.noFacilityPrice');
        return of([]);
      })
    );
  }

  getPageable(params: FacilitiesPageableParams): Observable<PageableModel<FacilityModel>> {
    return this.get<PageableModel<FacilityModel>>(`facilities/pageable`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError(() => of(new PageableModel<FacilityModel>()))
    );
  }

  getPageableByRepresentative(params: FacilitiesPageableParams): Observable<PageableModel<FacilityModel>> {
    return this.get<PageableModel<FacilityModel>>(
      `facilities/pageable/byRepresentative`,
      HttpHelperService.addResponseTypeJSON(params)
    ).pipe(catchError(() => of(new PageableModel<FacilityModel>())));
  }

  createFacility(body: FacilityModel): Observable<string> {
    return this.post<string>(`facilities`, body).pipe(
      map(id => {
        this.alertsService.showSuccess('shared.alerts.successMessages.created');
        return id;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  postFacilityParLevel(facilityId: string, params: FacilityParLevelRequestModel): Observable<void> {
    return this.post<void>(`facilities/${facilityId}/catalog`, params).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.saveParLevel');
        return of(null);
      })
    );
  }

  assignPriceSheet(facilityId: string, priceSheetId?: string): Observable<boolean> {
    return this.post<void>(
      `facilities/${facilityId}/pricing-sheet` + (priceSheetId ? `?facilityPricingSheetId=${priceSheetId}` : ''),
      {}
    ).pipe(
      map(() => {
        this.alertsService.showSuccess(priceSheetId ? 'shared.alerts.successMessages.assigned' : 'shared.alerts.successMessages.deleted');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  unassignPriceSheet(facilityId: string, showSuccessMessage: boolean = true): Observable<boolean> {
    return this.put<void>(`facilities/${facilityId}/pricing-sheet-delete`, {}).pipe(
      map(() => {
        if (showSuccessMessage) {
          this.alertsService.showSuccess('shared.alerts.successMessages.unassigned');
        }

        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updateFacility(id: string, body: FacilityModel): Observable<void> {
    return this.put<void>(`facilities/${id}`, body).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.saved')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }

  updatePrice(id: string, body: IUploadFacilityPrice): Observable<void> {
    return this.put<void>(`facilities/${id}/pricing`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.priceUpdated');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  uploadPrice(id: string, body: IUploadFacilityPrice): Observable<boolean> {
    return this.post<void>(`facilities/${id}/pricing`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.priceUpdated');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }

  getFacilityStates(searchText: string): Observable<string[]> {
    return this.get<string[]>(`facilities/states?searchText=${searchText}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  deletePrice(facilityId: string, manufacturerId: string, referenceNumber: string): Observable<void> {
    return this.delete<void>(`facilities/${facilityId}/pricing`, { params: { manufacturerId, referenceNumber } }).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getAttachments(id: string): Observable<ImageModel[]> {
    return this.get<ImageModel[]>(`facilities/${id}/attachments`);
  }

  putAttachmentUrl(id: string, imageUrl: string): Observable<boolean> {
    return this.put<string>(`facilities/${id}/attachment/url`, imageUrl).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  putFacilityContract(facilityId: string, isCompletedContract: boolean): Observable<boolean> {
    return this.put<void>(`facilities/${facilityId}/contract?isCompletedContract=${isCompletedContract}`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  useAlternateCatalogs(facilityId: string, value: boolean): Observable<boolean> {
    return this.put<void>(`facilities/${facilityId}/alternate-catalogs-enabled?useAlternateCatalogs=${value}`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  saveCreditHold(facilityId: string, value: boolean): Observable<boolean> {
    return this.put<void>(`facilities/${facilityId}/credit-hold?isCreditHold=${value}`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  changeGeographicalType(facilityId: string, type: TFacilityGeographicalType): Observable<boolean> {
    return this.put<void>(`facilities/${facilityId}/geographical-type?geographicalType=${type}`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  togglePoNumberEmails(facilityId: string, value: boolean): Observable<boolean> {
    return this.put<void>(`facilities/${facilityId}/ponumber-emails-enabled?poNumberEmailsEnabled=${value}`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  async uploadAttachment(id: string, formData: FormData): Promise<boolean> {
    const token: string = AuthenticationService.getToken();

    const res = await window.fetch(`${environment.apiUrlCore}facilities/${id}/attachment`, {
      method: 'PUT',
      body: formData,
      headers: { Authorization: `Bearer ${token}` }
    });
    if (res.status >= 400 && res.status < 600) {
      this.alertsService.showError('shared.alerts.errorMessages.loadAttachments');
      return Promise.reject();
    } else {
      return true;
    }
  }

  deleteAttachment(id: string, attachment: string): Observable<boolean> {
    return this.delete<void>(`facilities/${id}/attachment?facilityAttachmentId=${attachment}`).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.attachmentWasDeleted')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deleteFacilityContact(facilityContactId: string): Observable<boolean> {
    return this.delete<void>(`facilities/facilityContacts/${facilityContactId}`).pipe(
      map(() => true),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }
}
