import * as i0 from '@angular/core';
import { Injectable, InjectionToken, Directive, Inject, Optional, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/router';
import { PRIMARY_OUTLET } from '@angular/router';
import { EMPTY } from 'rxjs';

// Using a global registry so we can keep it populated across lazy-loaded
// modules with different parent injectors which create instance of the registry.
const globalRegistry = [];
class PrefetchRegistry {
  constructor(router) {
    this.router = router;
    this.trees = globalRegistry;
  }
  add(tree) {
    this.trees.push(tree);
  }
  shouldPrefetch(url) {
    const tree = this.router.parseUrl(url);
    return this.trees.some(containsTree.bind(null, tree));
  }
  static {
    this.ɵfac = function PrefetchRegistry_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrefetchRegistry)(i0.ɵɵinject(i1.Router));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrefetchRegistry,
      factory: PrefetchRegistry.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrefetchRegistry, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.Router
  }], null);
})();
function containsQueryParams(container, containee) {
  // TODO: This does not handle array params correctly.
  return Object.keys(containee).length <= Object.keys(container).length && Object.keys(containee).every(key => containee[key] === container[key]);
}
function containsTree(containee, container) {
  return containsQueryParams(container.queryParams, containee.queryParams) && containsSegmentGroup(container.root, containee.root, containee.root.segments);
}
function containsSegmentGroup(container, containee, containeePaths) {
  if (container.segments.length > containeePaths.length) {
    const current = container.segments.slice(0, containeePaths.length);
    if (!equalPath(current, containeePaths)) return false;
    if (containee.hasChildren()) return false;
    return true;
  } else if (container.segments.length === containeePaths.length) {
    if (!equalPath(container.segments, containeePaths)) return false;
    if (!containee.hasChildren()) return true;
    for (const c in containee.children) {
      if (!container.children[c]) break;
      if (containsSegmentGroup(container.children[c], containee.children[c], containee.children[c].segments)) return true;
    }
    return false;
  } else {
    const current = containeePaths.slice(0, container.segments.length);
    const next = containeePaths.slice(container.segments.length);
    if (!equalPath(container.segments, current)) return false;
    if (!container.children[PRIMARY_OUTLET]) return false;
    return containsSegmentGroup(container.children[PRIMARY_OUTLET], containee, next);
  }
}
function equalPath(as, bs) {
  if (as.length !== bs.length) return false;
  return as.every((a, i) => a.path === bs[i].path || a.path.startsWith(':') || bs[i].path.startsWith(':'));
}
const requestIdleCallback = typeof window !== 'undefined' ? window.requestIdleCallback || function (cb) {
  const start = Date.now();
  return setTimeout(function () {
    cb({
      didTimeout: false,
      timeRemaining: function () {
        return Math.max(0, 50 - (Date.now() - start));
      }
    });
  }, 1);
} : () => {};
const observerSupported = () => typeof window !== 'undefined' ? !!window.IntersectionObserver : false;
const LinkHandler = new InjectionToken('LinkHandler');
class ObservableLinkHandler {
  constructor(loader, registry, ngZone) {
    this.loader = loader;
    this.registry = registry;
    this.ngZone = ngZone;
    this.elementLink = new Map();
    this.observer = observerSupported() ? new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (!this.observer) {
          return;
        }
        if (entry.isIntersecting) {
          const link = entry.target;
          const routerLink = this.elementLink.get(link);
          if (!routerLink || !routerLink.urlTree) return;
          this.registry.add(routerLink.urlTree);
          this.observer.unobserve(link);
          requestIdleCallback(() => {
            this.loader.preload().subscribe(() => void 0);
          });
        }
      });
    }) : null;
  }
  register(el) {
    this.elementLink.set(el.element, el);
    this.ngZone.runOutsideAngular(() => {
      if (!this.observer) {
        return;
      }
      this.observer.observe(el.element);
    });
  }
  // First call to unregister will not hit this.
  unregister(el) {
    if (!this.observer) {
      return;
    }
    if (this.elementLink.has(el.element)) {
      this.observer.unobserve(el.element);
      this.elementLink.delete(el.element);
    }
  }
  supported() {
    return observerSupported();
  }
  static {
    this.ɵfac = function ObservableLinkHandler_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ObservableLinkHandler)(i0.ɵɵinject(i1.RouterPreloader), i0.ɵɵinject(PrefetchRegistry), i0.ɵɵinject(i0.NgZone));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ObservableLinkHandler,
      factory: ObservableLinkHandler.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ObservableLinkHandler, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.RouterPreloader
  }, {
    type: PrefetchRegistry
  }, {
    type: i0.NgZone
  }], null);
})();
class PreloadLinkHandler {
  constructor(loader, registry) {
    this.loader = loader;
    this.registry = registry;
  }
  register(el) {
    this.registry.add(el.urlTree);
    requestIdleCallback(() => this.loader.preload().subscribe(() => void 0));
  }
  unregister(_) {}
  supported() {
    return true;
  }
  static {
    this.ɵfac = function PreloadLinkHandler_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PreloadLinkHandler)(i0.ɵɵinject(i1.RouterPreloader), i0.ɵɵinject(PrefetchRegistry));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PreloadLinkHandler,
      factory: PreloadLinkHandler.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PreloadLinkHandler, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.RouterPreloader
  }, {
    type: PrefetchRegistry
  }], null);
})();
class LinkDirective {
  constructor(linkHandlers, el, link, linkWithHref) {
    this.linkHandlers = linkHandlers;
    this.el = el;
    this.linkHandler = this.linkHandlers.filter(h => h.supported()).shift();
    this.rl = link || linkWithHref;
    if (this.element && this.element.setAttribute) {
      this.element.setAttribute('ngx-ql', '');
    }
  }
  ngOnChanges(c) {
    if (c['routerLink'] && this.linkHandler) {
      this.linkHandler.unregister(this);
      this.linkHandler.register(this);
    }
  }
  ngOnDestroy() {
    if (!this.linkHandler) {
      return;
    }
    this.linkHandler.unregister(this);
  }
  get element() {
    return this.el.nativeElement;
  }
  get urlTree() {
    return this.rl.urlTree;
  }
  static {
    this.ɵfac = function LinkDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LinkDirective)(i0.ɵɵdirectiveInject(LinkHandler), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.RouterLink, 8), i0.ɵɵdirectiveInject(i1.RouterLink, 8));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: LinkDirective,
      selectors: [["", "routerLink", ""]],
      inputs: {
        routerLink: "routerLink"
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinkDirective, [{
    type: Directive,
    args: [{
      selector: '[routerLink]',
      standalone: true
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LinkHandler]
    }]
  }, {
    type: i0.ElementRef
  }, {
    type: i1.RouterLink,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i1.RouterLink,
    decorators: [{
      type: Optional
    }]
  }], {
    routerLink: [{
      type: Input
    }]
  });
})();
const findPath = (config, route) => {
  config = config.slice();
  const parent = new Map();
  const visited = new Set();
  while (config.length) {
    const el = config.shift();
    if (!el) {
      continue;
    }
    visited.add(el);
    if (el === route) break;
    let children = el.children || [];
    const current = el._loadedRoutes || [];
    for (const route of current) {
      if (route && route.children) {
        children = children.concat(route.children);
      }
    }
    children.forEach(r => {
      if (visited.has(r)) return;
      parent.set(r, el);
      config.push(r);
    });
  }
  let path = '';
  let current = route;
  while (current) {
    if (isPrimaryRoute(current)) {
      path = `/${current.path}${path}`;
    } else {
      path = `/(${current.outlet}:${current.path}${path})`;
    }
    current = parent.get(current);
  }
  // For routes with empty paths (the resulted string will look like `///section/sub-section`)
  return path.replace(/[\/]+/, '/');
};
function isPrimaryRoute(route) {
  return route.outlet === PRIMARY_OUTLET || !route.outlet;
}
class QuicklinkStrategy {
  constructor(registry, router) {
    this.registry = registry;
    this.router = router;
    this.loading = new Set();
  }
  preload(route, load) {
    if (this.loading.has(route)) {
      // Don't preload the same route twice
      return EMPTY;
    }
    const conn = typeof navigator !== 'undefined' ? navigator.connection : undefined;
    if (conn) {
      // Don't preload if the user is on 2G. or if Save-Data is enabled..
      if ((conn.effectiveType || '').includes('2g') || conn.saveData) return EMPTY;
    }
    // Prevent from preloading
    if (route.data && route.data['preload'] === false) {
      return EMPTY;
    }
    const fullPath = findPath(this.router.config, route);
    if (this.registry.shouldPrefetch(fullPath)) {
      this.loading.add(route);
      return load();
    }
    return EMPTY;
  }
  static {
    this.ɵfac = function QuicklinkStrategy_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || QuicklinkStrategy)(i0.ɵɵinject(PrefetchRegistry), i0.ɵɵinject(i1.Router));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: QuicklinkStrategy,
      factory: QuicklinkStrategy.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuicklinkStrategy, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: PrefetchRegistry
  }, {
    type: i1.Router
  }], null);
})();
const quicklinkProviders = [{
  provide: LinkHandler,
  useClass: ObservableLinkHandler,
  multi: true
}, {
  provide: LinkHandler,
  useClass: PreloadLinkHandler,
  multi: true
}, PrefetchRegistry, QuicklinkStrategy];
class QuicklinkModule {
  static {
    this.ɵfac = function QuicklinkModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || QuicklinkModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: QuicklinkModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: quicklinkProviders
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuicklinkModule, [{
    type: NgModule,
    args: [{
      imports: [LinkDirective],
      exports: [LinkDirective],
      providers: quicklinkProviders
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-quicklink
 */

/**
 * Generated bundle index. Do not edit.
 */

export { LinkDirective as QuicklinkDirective, QuicklinkModule, QuicklinkStrategy, quicklinkProviders };
