import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { TranslationsKeys } from '@shared/type/i18n.type';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EmptyStateComponent {
  @Input() label: TranslationsKeys = 'shared.labels.noItems';
  @Input() additionalLabel: TranslationsKeys = null;
  @Input() dataCy: string = 'noSearchedItems';
  @Input() resetPadding: boolean = false;
  @Input() resetHeight: boolean = false;
  @Input() customTemplateBottom: TemplateRef<any>;
  @Input() padding: 'padding-16' = null;
}
