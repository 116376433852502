import { PermissionModel } from '@shared/models';
import { ManufacturerAdminRole } from '../roles/manufacturer-company/manufacturer.admin.role';
import { ErrorRole } from '../roles/error.role';
import { ManufacturerSalesRole } from '../roles/manufacturer-company/manufacturer.sales.role';
import { ManufacturerDistributorRole } from '../roles/manufacturer-company/manufacturer.distributor.role';
import { TRoles } from '@shared/type/index.type';
import { ManufacturerRegionalManagerRole } from '../roles/manufacturer-company/manufacturer.regional-manager.role';

export class ManufacturerCompanyTypeFactory {
  createRoles(role: TRoles): PermissionModel {
    switch (role) {
      case 'ADMIN':
        return new ManufacturerAdminRole().model;
      case 'DISTRIBUTOR':
        return new ManufacturerDistributorRole().model;
      case 'SALES':
        return new ManufacturerSalesRole().model;
      case 'REGIONAL_MANAGER':
        return new ManufacturerRegionalManagerRole().model;
      default:
        return new ErrorRole().model;
    }
  }
}
