import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatMenu } from '@angular/material/menu';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { TDebounceEvents, TIcons } from '@shared/type/index.type';
import { OpenLinkInBrowser } from '@shared/utils/cross-platform/open-link-in-browser';
import { TrackById } from '@shared/utils/form-elements/track-by';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-input-with-chips-kit',
  templateUrl: './input-with-chips-kit.component.html',
  styleUrls: ['./input-with-chips-kit.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputWithChipsKitComponent),
      multi: true
    }
  ],
  standalone: false
})
export class InputWithChipsKitComponent implements ControlValueAccessor {
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() placeholder: string = '';
  @Input() label: string = 'Label';
  @Input('disabled') set disabledState(data: boolean) {
    if (data) {
      this.showChipsInput = true;
      this.showAddChipButton = false;
    } else {
      this.showChipsInput = false;
      this.showAddChipButton = true;
    }
    this.disabled = data;
  }
  @Input() required: boolean = false;
  @Input() requiredView: boolean = false;
  @Input() dataCy: string = '';
  @Input() chipDataCy: string = '';
  @Input() chipRemoveData_cy: string = '';
  @Input() selectedChips: any[] = [];
  @Input() selectable: boolean = false;
  @Input() debounceEvents: TDebounceEvents[] = [];
  @Input() debounceTime: number = 0;
  @Input() autocompleteName: MatAutocomplete = null;
  @Input() removable: boolean = false;
  @Input() hint: string = '';
  @Input() error: string = '';
  @Input() icons: TIcons[] = [];
  @Input() type: string = 'text';
  @Input() showLinkToDetail: boolean = true;
  @Input() detailPageLink: string = '';
  @Input() parseChipByProperty: string = 'name';
  @Input() showAddChipButton: boolean = false;
  @Input() set clearInput(data: boolean) {
    if (data) {
      if (this.input?.nativeElement) {
        this.input.nativeElement.value = '';
      }
      this.inputCleared.emit();
    }
  }
  @Input() set forceBlurEvent(data: boolean) {
    if (data) {
      setTimeout(() => this.autocomplete.closePanel());
    }
  }
  @Input() separatorKeysCodes: number[] = [];
  @Input() addOnBlur: boolean = false;
  @Input() invalidInput: boolean = false;
  @Input() tooltipText: TranslationsKeys;
  @Input() matMenuName: MatMenu = null;
  @Input() autoScrollOnFocus: boolean = false;
  @Input() skeleton: boolean = false;
  @Input() filterView: boolean = false;

  @Output() chipsRemovedEmitter = new EventEmitter<any>();
  @Output() debounceTimeEndedEmitter = new EventEmitter<string>();
  @Output() inputCleared = new EventEmitter<void>();
  @Output() iconClickEmitter = new EventEmitter<TIcons>();
  @Output() chipInputEndEmitter = new EventEmitter<MatChipInputEvent>();

  trackBy = TrackById;
  focused: boolean = false;
  value: string = '';
  disabled: boolean = false;
  showChipsInput: boolean = false;
  touched$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly openLinkInBrowser = OpenLinkInBrowser;

  onChange: any = () => {};
  onTouch: any = () => {};

  scrollTo(element: HTMLInputElement): void {
    if (!this.autoScrollOnFocus) {
      return;
    }

    element.scrollIntoView({ behavior: 'smooth' });
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  checkValueBeforeEmit(value: string): void {
    if (!this.placeholder) {
      this.debounceTimeEndedEmitter.emit(value);
      return;
    }

    if (value.length >= 2) {
      this.debounceTimeEndedEmitter.emit(value);
    }
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  focusInput(): void {
    setTimeout(() => this.input.nativeElement.focus());
  }

  updateValue(value: string) {
    if (value !== undefined && this.value !== value) {
      this.value = value;
      this.onChange(value);
      this.onTouch();
    }
  }
}
