import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IAnchor } from '@shared/interfaces';
import { TRoles } from '@shared/type/index.type';

@Component({
  selector: 'app-info-block',
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class InfoBlockComponent {
  @Input() data: {
    createdBy?: { name: string; role: TRoles } | null;
    createdDatetime?: string | Date;
    modifiedBy?: { name: string } | null;
    modifiedDatetime?: string | Date;
  };
  @Input() additionalData: { key: string; value: string; dataCy?: string; routerLink?: string }[] = [];
  @Input() anchors: IAnchor[] = [];
  @Input() showModified: boolean = true;
  @Input() hideBorder: boolean = false;
  @Input() containersPossible: number = null;
  @Input() facilityPricingUpdatedOn: string = null;
}
