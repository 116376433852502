import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { BehaviorSubject, finalize, take } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { ModifiersService } from '@services/modifiers.service';
import { IModifier } from '@shared/interfaces';
import { FilterUniqueArrayByProperty } from '@shared/utils/arrays/filter-unique-array';
import { TIcons, TModifier } from '@shared/type/index.type';
import { DisplayModifierName } from '@shared/utils/form-elements/display-with-autocomplete';
import { DefaultAutoCompleteSize } from '@constants';

@Component({
  selector: 'app-modifiers-autocomplete',
  templateUrl: './modifiers-autocomplete.component.html',
  styleUrls: ['./modifiers-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ModifiersAutocompleteComponent {
  @Input() multiple: boolean = false;
  @Input() selectedChips: { modifierName: string }[] = [];
  @Input() disabled: boolean = false;
  @Input() removable: boolean = true;
  @Input() label: TranslationsKeys = 'shared.labels.modifiers';
  @Input() autocompleteData: { useAutocomplete: boolean; data: string[] } = { useAutocomplete: false, data: [] };
  @Input() type: TModifier = null;
  @Input() modifier: { modifierName: string } = { modifierName: '' };
  @Input() showClearIcon: boolean = false;
  @Input() skeleton: boolean = false;

  @Output() removeChipEmitter = new EventEmitter<{ modifierName: string }>();
  @Output() optionSelectedEmitter = new EventEmitter<string>();

  private modifiersService = inject(ModifiersService);

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initialLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  searchedData$: BehaviorSubject<IModifier[]> = new BehaviorSubject<IModifier[]>([]);
  waitToBeAdded: boolean = false;
  clearInput: boolean = true;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  readonly displayFn = DisplayModifierName;

  doIconAction(icon: TIcons): void {
    if (icon === 'close') {
      this.optionSelectedEmitter.emit('');
      this.getModifiers('');
    }
  }

  getModifiers(value: string, page: number = 0): void {
    this.initialLoading$.next(false);
    this.loading$.next(true);
    this.searchedData$.next([]);

    if (this.autocompleteData.useAutocomplete) {
      const mockModifiers: IModifier[] = this.autocompleteData.data.map(modifier => ({
        id: '',
        modifierName: modifier,
        organization: null,
        createdBy: '',
        entityType: null
      }));

      this.searchedData$.next(FilterUniqueArrayByProperty(mockModifiers, this.selectedChips, 'modifierName'));
      this.loading$.next(false);
      return;
    }

    this.modifiersService
      .getModifiers({ modifierName: value, entityType: this.type, size: DefaultAutoCompleteSize, page })
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(data => {
        this.searchedData$.next(FilterUniqueArrayByProperty(data.content, this.selectedChips, 'modifierName'));
        //CC-5432 Load next page if all items selected
        if (this.searchedData$.value?.length === 0 && data?.totalElements > this.selectedChips?.length && page < data.totalPages) {
          setTimeout(() => this.getModifiers(value, page + 1));
        }
      });
  }

  selectModifier(value: string, preventDoubleSelect: boolean = false): void {
    if (!this.multiple) {
      this.optionSelectedEmitter.emit(value);
      return;
    } else {
      if (this.multiple) {
        //Hide selected item from search results
        this.searchedData$.next(this.searchedData$.value.filter(m => m.modifierName !== value));
      }
    }

    /** Do not emmit en event if it triggers on blur and the option was selected from autocomplete*/
    this.waitToBeAdded = false;
    if (preventDoubleSelect) {
      setTimeout(() => {
        if (!this.waitToBeAdded) {
          if (value?.length) {
            this.optionSelectedEmitter.emit(value);
          }
          this.waitToBeAdded = false;
        }
      }, 500);
    } else {
      this.waitToBeAdded = true;
      this.optionSelectedEmitter.emit(value);
    }
  }

  removeChip(value: { modifierName: string }): void {
    this.removeChipEmitter.emit(value);
  }
}
