import { HttpContextToken } from '@angular/common/http';
import { MatDialogConfig } from '@angular/material/dialog';
import { IConfirmParams } from '@shared/interfaces';
import { TCurrency, TDateFormat } from '@shared/type/index.type';

const DefaultCurrency: TCurrency = 'USD';
const DefaultDateFormat: TDateFormat = 'MM_DD_YYYY';
const DefaultPagination: number = 20;
// Default count of items/options requested/showed in autocompletes
const DefaultAutoCompleteSize: number = 20;
// On change this variable also change it at validate-csv.worker.ts, web worker does not support import constants from other files
const MaxContainerChildrenCount: number = 650;
//Total sum of quantity for all devices that can be marked to event t one time
const MaxTotalCountMUIDevices: number = 5000;
// Total count of different devices that can be marked to event t one time
const MaxDifferentDevicesMUICount: number = 150;
// Max quantity that can be set to inventory at once
const createInventoryMaxQuantity: number = 5000;
const CreateMediumEntityConfig: MatDialogConfig = {
  disableClose: true,
  width: '980px',
  maxWidth: '100vw',
  height: '624px',
  maxHeight: '624px',
  autoFocus: false,
  panelClass: 'largeModalWindow'
};
const CreateSmallEntityConfig: MatDialogConfig = {
  disableClose: true,
  width: '506px',
  maxWidth: '100vw',
  height: '592px',
  maxHeight: '592px',
  autoFocus: false,
  panelClass: 'largeModalWindow'
};
const ConfirmModalConfig = (data: IConfirmParams): MatDialogConfig => ({
  width: '400px',
  disableClose: true,
  panelClass: 'confirmModalWindow',
  data: { ...data }
});
const SKIP_AUTH_TOKEN = new HttpContextToken(() => false);

export {
  DefaultCurrency,
  DefaultDateFormat,
  CreateMediumEntityConfig,
  CreateSmallEntityConfig,
  ConfirmModalConfig,
  DefaultPagination,
  MaxContainerChildrenCount,
  MaxTotalCountMUIDevices,
  MaxDifferentDevicesMUICount,
  createInventoryMaxQuantity,
  SKIP_AUTH_TOKEN,
  DefaultAutoCompleteSize
};
