import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { debounce, from, fromEvent, map, mergeMap, of, takeUntil, timer } from 'rxjs';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { TDebounceEvents } from '@shared/type/index.type';

@Directive({
  selector: '[appDebounceEvent]',
  standalone: false
})
export class DebounceEventDirective extends DestroySubscriptions implements OnInit, OnDestroy {
  @Input() debounceTime: number = 1000;
  @Input() debounceEvents: TDebounceEvents[] = [];

  @Output() debounceEventEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor(private elementRef: ElementRef) {
    super();
  }

  ngOnInit() {
    from(this.debounceEvents)
      .pipe(
        mergeMap(event =>
          fromEvent(this.elementRef.nativeElement, event).pipe(
            debounce((ev: any) => (ev.keyCode === 13 ? of({}) : timer(this.debounceTime))),
            map((ev: any) => ev.target.value)
          )
        )
      )
      .pipe(takeUntil(this.subscriptions))
      .subscribe(e => this.debounceEventEmitter.emit(e));
  }

  ngOnDestroy() {
    this.unSubscribe();
  }
}
