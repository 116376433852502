import { Pipe, PipeTransform } from '@angular/core';
import { UsersService } from '@services/users.service';
import { UserTimeZone } from '@shared/models';
import { formatInTimeZone } from 'date-fns-tz';
import { TDateFormat } from '@shared/type/index.type';

@Pipe({
  name: 'userDate',
  standalone: false
})
export class UserDatePipe implements PipeTransform {
  private readonly userTimeZone: UserTimeZone = UsersService.userTimeZone ?? new UserTimeZone();
  private readonly orgDateFormat: TDateFormat = UsersService.dateFormat;

  transform(value: string | Date, dateFormat?: string, timezone?: string): string {
    const timeFormat: string = 'hh:mm a';

    if (!value) {
      return null;
    }

    const timeZone = timezone === 'UTC' ? 'UTC' : this.userTimeZone.timeZone;
    const utcDate = typeof value === 'string' ? new Date(value) : value;

    switch (this.orgDateFormat) {
      case 'DD_MM_YYYY':
        return formatInTimeZone(utcDate, timeZone, `d MMM, yyyy${dateFormat ? '' : ' ' + timeFormat}`);
      case 'MM_DD_YYYY':
        return formatInTimeZone(utcDate, timeZone, `MMM d, yyyy${dateFormat ? '' : ' ' + timeFormat}`);
      case 'YYYY_MM_DD':
        return formatInTimeZone(utcDate, timeZone, `yyyy MMM d${dateFormat ? '' : ' ' + timeFormat}`);
      default:
        return formatInTimeZone(utcDate, timeZone, `d MMM, yyyy${dateFormat ? '' : ' ' + timeFormat}`);
    }
  }
}
