import { TCurrency, TDateFormat } from '@shared/type/index.type';
import { ManufacturerModel, UserModel } from '@shared/models';
import { UniqueId } from '@shared/models/shared/shared-kernel';
import { EAccountingConfig } from '@shared/enum/accounting-config';

export class OrganizationModel {
  company: ManufacturerModel;
  readonly id: UniqueId;
  readonly identifier: string;
  startInvoiceNumber: number | null;
  createdDatetime: Date | string;
  modifiedDatetime: Date | string;
  poNumberEmailsEnabled: boolean;
  currency: TCurrency;
  dateFormat: TDateFormat;
  addAndDeactivateInventoriesEnabled: boolean;
  isExtendedQuickBooksEnabled: boolean;
  isIntegrationMessagingEnabled: boolean;
  initiateTransfersEnabled: boolean;
  reopenEventsEnabled: boolean;
  formNumber: string;
  requireEventNotesEnabled: boolean;
  requireInventoryEnabled: boolean;
  showProcedureAssignedToPhysician: boolean;
  isLookerStudioEnabled: boolean;
  isAutoTriggerSalesOrderEmail: boolean;
  isAutoTriggerInvoiceEmail: boolean;
  requireTransferEmailsEnabled: boolean;
  isAuditEnabled: boolean;
  isQuickBooksEnabled: boolean;
  isReadOnly: boolean;
  isUdiEnabled: boolean;
  isXeroEnabled: boolean;
  salesOrderAccountingConfig: keyof typeof EAccountingConfig;
  isDemoModeOnly: boolean;
  isAutoResetDueBackDateEnabled: boolean;
  dueBackDaysReset: number;
  isDecontaminationConfirmationEnabled: boolean;
  decontaminationConfirmationLink: string;
  discrepantCustody: UserModel;
  isCaseNoPhysicianEnabled: boolean;
  isEndgrateIntegrationEnabled: boolean;
  isShippoEnabled: boolean;

  constructor() {
    this.company = new ManufacturerModel();
  }
}
