import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { IBottomSheet, IBottomSheetItem } from '@shared/interfaces/bottom-sheet';

@Component({
  selector: 'app-bottom-sheet-options',
  templateUrl: './bottom-sheet-options.component.html',
  styleUrls: ['./bottom-sheet-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class BottomSheetOptionsComponent {
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: IBottomSheet,
    private bottomSheetRef: MatBottomSheetRef<BottomSheetOptionsComponent>
  ) {}

  cancel(result?: IBottomSheetItem): void {
    this.bottomSheetRef.dismiss(result);
  }
}
