import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { BehaviorSubject, finalize, take } from 'rxjs';
import { DisplayLocationStateName } from '@shared/utils/form-elements/display-with-autocomplete';
import { IFilter } from '@shared/interfaces';
import { LocationStateModel } from '@shared/models';
import { LanguageService } from '@services/internal/language.service';
import { LocationStatePageableParams } from '@shared/models/build-models';
import { FilterUniqueArray } from '@shared/utils/arrays/filter-unique-array';
import { LocationStateService } from '@services/location-state.service';

@Component({
  selector: 'app-location-state-autocomplete',
  templateUrl: './location-state-autocomplete.component.html',
  styleUrls: ['./location-state-autocomplete.component.scss'],
  standalone: false
})
export class LocationStateAutocompleteComponent {
  @Input() multiple: boolean = false;
  @Input() selectedChips: LocationStateModel[] = [];
  @Input() removable: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() invalid: boolean = false;
  @Input() locationState: LocationStateModel = new LocationStateModel();
  @Input() label: string = LanguageService.instant('shared.labels.locationState');
  @Input() ignoreUsedLocation: boolean = false;
  @Input() placeholder: string = '';
  @Input() showFilterByUserCountry: boolean = false;

  @Output() removeChipEmitter = new EventEmitter<string>();
  @Output() selectedOptionEmitter = new EventEmitter<LocationStateModel>();

  private locationStateService = inject(LocationStateService);

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initialLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  searchedData: LocationStateModel[] = [];
  country: string = '';
  useFilterByCountry: boolean = true;
  forceBlurEvent: boolean = false;

  readonly displayFn = DisplayLocationStateName;

  async searchLocationState(value: string): Promise<void> {
    if (this.showFilterByUserCountry && this.useFilterByCountry && !this.country) {
      await this.getUserCountry();
    }

    this.initialLoading$.next(false);
    this.forceBlurEvent = false;

    if (this.loading$.value) {
      return;
    }

    this.loading$.next(true);
    this.searchedData = [];

    const params: IFilter = {
      ignoreUsedLocation: this.ignoreUsedLocation,
      search: value,
      size: 30,
      state: 'ACTIVE',
      sort: 'locationStateName,asc'
    };

    if (this.useFilterByCountry) {
      params.countryName = this.country;
    }

    const queryParams: LocationStatePageableParams = new LocationStatePageableParams(params);

    this.locationStateService
      .getPageable(queryParams)
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(data => {
        if (this.multiple) {
          this.searchedData = FilterUniqueArray(data.content, this.selectedChips);
        } else {
          this.searchedData = data.content;
        }

        if (!value && this.searchedData.length === 1) {
          this.selectOption(this.searchedData[0]);
          this.forceBlurEvent = true;
        } else {
          this.forceBlurEvent = false;
        }
      });
  }

  removeChip(id: string): void {
    this.removeChipEmitter.emit(id);
  }

  selectOption(locationState: LocationStateModel): void {
    this.selectedOptionEmitter.emit(locationState);
    if (this.multiple) {
      //Hide selected item from search results
      this.searchedData = this.searchedData.filter(c => c.id !== locationState.id);
    }
  }

  private async getUserCountry(): Promise<void> {
    const userTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const geocoder: google.maps.Geocoder = new google.maps.Geocoder();

    await geocoder.geocode({ address: userTimeZone }, results => {
      this.country = results[0].address_components.find(a => a.types[0] === 'country').long_name;
    });
  }
}
