import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, filter, map } from 'rxjs';
import { IMobileActions } from '@shared/interfaces/mobile-actions';
import { UsersService } from '@services/users.service';
import { ManufacturerService } from '@services/manufacturer.service';
import { Title } from '@angular/platform-browser';
import { DashboardService } from '@services/dashboard.service';
import { PermissionService } from '@services/internal/permission.service';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { AlertsService } from '@services/internal/alerts.service';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { TPlatform } from '@shared/type/index.type';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { GetBrowser } from '@shared/utils/get-browser';

const pl: string = Capacitor.getPlatform();
const plt: TPlatform = pl === 'web' ? 'web' : pl === 'ios' ? 'ios' : 'android';

/** Subscription to change isMobile property in state */

@Injectable({
  providedIn: 'root'
})
export class GlobalStoreService {
  private titleService = inject(Title);
  private usersService = inject(UsersService);
  private manufacturerService = inject(ManufacturerService);
  private dashboardService = inject(DashboardService);
  private permissionService = inject(PermissionService);
  private alertsService = inject(AlertsService);
  private router = inject(Router);
  private breakpointObserver = inject(BreakpointObserver);

  static loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  static screenTitle$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  static mobileActions$: BehaviorSubject<IMobileActions> = new BehaviorSubject<IMobileActions>({ status: '', class: '' });
  static isMobile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  static isWebChromeBrowser: boolean = GetBrowser().browserName === 'Google Chrome' && plt === 'web';
  static getPlatform(): TPlatform {
    return plt;
  }

  constructor() {
    document.body.classList.add('platform-' + plt);
    this.setScreenTitleWatcher();
    this.onChangeScreenWidth();
    if (GlobalStoreService.getPlatform() === 'web') {
      this.storageWatcher();
    }
  }

  /** Log out if another user log in another tab */
  private storageWatcher(): void {
    fromEvent<StorageEvent>(window, 'storage')
      .pipe(
        // listen for state changes
        filter((evt: StorageEvent) => evt?.key === 'userId'),
        filter((evt: any) => evt.newValue !== null),
        map(async evt => {
          const platform = GlobalStoreService.getPlatform();
          if (
            evt.newValue !== evt.oldValue &&
            platform === 'web' &&
            !location.href.includes('create-request') &&
            !location.href.includes('ponumber')
          ) {
            this.router.navigate(['/login']);
            this.alertsService.showSuccess('shared.alerts.successMessages.loggedInAnotherTab', null, 15000);
          }
        })
      )
      .subscribe();
  }

  // Clear all local stores here
  clearStore() {
    this.usersService.destroy();
    this.manufacturerService.destroy();
    this.dashboardService.destroy();
    this.permissionService.destroy();
  }

  private onChangeScreenWidth() {
    this.breakpointObserver
      .observe(['(max-width: 1279px)'])
      .pipe(map((state: BreakpointState) => state.matches))
      .subscribe((isMobile: boolean) => {
        const value: boolean = isMobile || GlobalStoreService.getPlatform() !== 'web';
        GlobalStoreService.isMobile$.next(value);
      });
  }

  private setScreenTitleWatcher() {
    /** Get and Set Page title */
    GlobalStoreService.screenTitle$.pipe(distinctUntilChanged()).subscribe(pageTitle => {
      if (pageTitle) {
        this.titleService.setTitle(pageTitle);
      }
    });
  }
}
