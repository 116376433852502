<div fxLayout="row" fxLayoutAlign="space-between center" class="titleContainer">
  <app-title [capitalize]="false" [type]="(isMobile$ | async) === true ? 'h5' : 'h4'" [title]="data?.title ?? 'Confirmation'"></app-title>

  <app-button-kit
    *ngIf="data?.showCloseButton"
    [icon]="'close'"
    [defaultIconButton]="true"
    [title]="''"
    [dataCy]="'closeModalButton'"
    [textColor]="'primary_text'"
    (clickEmitter)="close(null)"
  ></app-button-kit>
</div>

<div fxLayout="row" class="largeModalWindowContent">
  <span [innerHTML]="data?.description"></span>
</div>

<div
  fxLayoutAlign="center"
  class="largeModalWindowButtonsContainer"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutGap="16px"
  fxLayoutGap.xs="8px"
>
  <app-button-kit
    fxFlex="50%"
    fxFlex.xs="100%"
    [type]="'outline'"
    [title]="data?.declineButtonText ?? ('shared.labels.cancel' | language)"
    [color]="data?.declineButtonStyle ?? 'primary'"
    [fontWeight]="600"
    [size]="(isMobile$ | async) === true ? 'medium' : 'large'"
    (clickEmitter)="close(false)"
  ></app-button-kit>
  <app-button-kit
    fxFlex="50%"
    fxFlex.xs="100%"
    [dataCy]="'confirmActionButton'"
    [type]="'primary'"
    [color]="data?.acceptButtonStyle ?? 'success'"
    [textColor]="data?.acceptButtonStyle ? null : 'white'"
    [title]="data?.acceptButtonText ?? ('shared.labels.confirm' | language)"
    [fontWeight]="600"
    [size]="(isMobile$ | async) === true ? 'medium' : 'large'"
    (clickEmitter)="close(true)"
  ></app-button-kit>
</div>
