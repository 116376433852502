import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tab-ui',
  templateUrl: './tab-ui.component.html',
  styleUrls: ['./tab-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class TabUIComponent {
  @Input() selected: boolean = false;
  @Input() title: string = '';
  @Input() disabled: boolean = false;
  @Input() customClass: 'dashboardTab' = null;

  @Output() clickEmitter = new EventEmitter<void>();
}
