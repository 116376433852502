import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { take, takeUntil } from 'rxjs';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { TrackById } from '@shared/utils/form-elements/track-by';
import { PermissionService } from '@services/internal/permission.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateMediumEntityConfig } from '@constants';
import { CreateManufacturerComponent } from 'src/app/features/directory/manufacturers/partials/modals/create-manufacturer/create-manufacturer.component';
import { MatAutocompleteRequireMath } from '@shared/validators/mat-autocomplete-require-math';
import { ManufacturerService } from '@services/manufacturer.service';
import { ProductsStoreService } from '../../services/products-store.service';

@Component({
  selector: 'app-form-product-line-step-first',
  templateUrl: './form-product-line-step-first.component.html',
  styleUrls: ['./form-product-line-step-first.component.scss'],
  standalone: false
})
export class FormProductLineStepFirstComponent extends DestroySubscriptions implements OnInit {
  @Input() createEntityFlow: boolean = false;
  @Input() modifiers: { modifierName: string }[] = [];

  @Output() formValueChangeEmitter = new EventEmitter<UntypedFormGroup>();

  requiredFields: UntypedFormGroup;
  readonly trackBy = TrackById;

  constructor(
    public permissionService: PermissionService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private manufacturerService: ManufacturerService,
    private productsStoreService: ProductsStoreService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.formChanges();
  }

  selectModifier(value: string): void {
    this.requiredFields.markAsTouched();
    this.requiredFields.markAsDirty();
    this.productsStoreService.selectModifier(value);
    this.formChanges();
  }

  removeModifier(item: { modifierName: string }): void {
    this.requiredFields.markAsTouched();
    this.requiredFields.markAsDirty();
    this.productsStoreService.removeModifier(item);
    this.formChanges();
  }

  createManufacturer(): void {
    const dialogConfig: MatDialogConfig = { ...CreateMediumEntityConfig };
    dialogConfig.data = false;
    const dialogRef = this.dialog.open(CreateManufacturerComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(id => {
        if (id) {
          this.manufacturerService
            .getManufacturer(id)
            .pipe(take(1))
            .subscribe(manufacturer => {
              this.requiredFields.get('manufacturerId').setValue(manufacturer);
            });
        }
      });
  }

  private formChanges(): void {
    this.formValueChangeEmitter.emit(this.requiredFields);
    this.requiredFields.valueChanges.pipe(takeUntil(this.subscriptions)).subscribe(() => {
      this.formValueChangeEmitter.emit(this.requiredFields);
    });
  }

  private initForm(): void {
    this.requiredFields = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      manufacturerId: ['', [Validators.required, MatAutocompleteRequireMath]]
    });
    if (!this.createEntityFlow) {
      this.requiredFields.get('manufacturerId').disable();
    }
  }
}
