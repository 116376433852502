export enum EUserLevelSettings {
  manageEventsAllowed = 'shared.enums.userLevelSettings.manageEventsAllowed',
  changePriceAllowed = 'shared.enums.userLevelSettings.editAnyPrice',
  requirePatientStickerUploadAllowed = 'shared.enums.userLevelSettings.requirePatientSticker',
  requireDeviceLabelUploadedAllowed = 'shared.enums.userLevelSettings.requireDeviceLabels',
  inventoryCreationAllowed = 'shared.enums.userLevelSettings.inventoryCreation',
  editContainerCatalogNumberAllowed = 'shared.enums.userLevelSettings.editContainerCatalog',
  editCatalogNumberAllowed = 'shared.enums.userLevelSettings.editCatalogNumber',
  removeExpirationDateAllowed = 'shared.enums.userLevelSettings.removeExpirationDates',
  invDeactivationAndStockEventCreationAllowed = 'shared.enums.userLevelSettings.makeInventoryAdjustments',
  manageInventoryMovementsAllowed = 'shared.enums.userLevelSettings.manageInventoryMovements',
  modifyReplenishmentRequestsAllowed = 'shared.enums.userLevelSettings.modifyReplenishmentRequests',
  manageBomsAllowed = 'shared.enums.userLevelSettings.manageBOM',
  addDirectoryItemsAllowed = 'shared.enums.userLevelSettings.addDirectoryItems',
  manageDirectoryItemsAllowed = 'shared.enums.userLevelSettings.manageDirectoryItems',
  manageFacilityPricingAllowed = 'shared.enums.userLevelSettings.manageFacilityPricing',
  viewFacilityContractPricesAllowed = 'shared.enums.userLevelSettings.viewContractPrices',
  createAndInviteUserAllowed = 'shared.enums.userLevelSettings.inviteUsers',
  manageUserAllowed = 'shared.enums.userLevelSettings.manageUsers',
  viewCommissionsAllowed = 'shared.enums.userLevelSettings.viewCommissions',
  userSettingUpdateAllowed = 'shared.enums.userLevelSettings.updateUserSettings',
  manageIntegrationsAllowed = 'shared.enums.userLevelSettings.manageIntegrations',
  viewReportsAllowed = 'shared.enums.userLevelSettings.viewReports',
  transferInventoryFromCustodianOnlyAllowed = 'shared.enums.userLevelSettings.transferInventoryFromCustodianOnlyAllowed'
}

export enum EUserLevelSettingsDescription {
  addDirectoryItemsAllowed = 'shared.enums.userLevelSettingsDescription.addDirectoryItems',
  viewFacilityContractPricesAllowed = 'shared.enums.userLevelSettingsDescription.viewContractPrices',
  manageBomsAllowed = 'shared.enums.userLevelSettingsDescription.manageBOM',
  requirePatientStickerUploadAllowed = 'shared.enums.userLevelSettingsDescription.requirePatientSticker',
  requireDeviceLabelUploadedAllowed = 'shared.enums.userLevelSettingsDescription.requireDeviceLabels',
  changePriceAllowed = 'shared.enums.userLevelSettingsDescription.editAnyPrice',
  viewCommissionsAllowed = 'shared.enums.userLevelSettingsDescription.viewCommissions',
  viewReportsAllowed = 'shared.enums.userLevelSettingsDescription.viewReports',
  createAndInviteUserAllowed = 'shared.enums.userLevelSettingsDescription.inviteUsers',
  manageUserAllowed = 'shared.enums.userLevelSettingsDescription.manageUsers',
  userSettingUpdateAllowed = 'shared.enums.userLevelSettingsDescription.updateUserSettings',
  manageEventsAllowed = 'shared.enums.userLevelSettingsDescription.manageEventsAllowed',
  manageIntegrationsAllowed = 'shared.enums.userLevelSettingsDescription.manageIntegrations',
  invDeactivationAndStockEventCreationAllowed = 'shared.enums.userLevelSettingsDescription.makeInventoryAdjustments',
  manageFacilityPricingAllowed = 'shared.enums.userLevelSettingsDescription.manageFacilityPricing',
  manageDirectoryItemsAllowed = 'shared.enums.userLevelSettingsDescription.manageDirectoryItems',
  editContainerCatalogNumberAllowed = 'shared.enums.userLevelSettingsDescription.editContainerCatalog',
  editCatalogNumberAllowed = 'shared.enums.userLevelSettingsDescription.editCatalogNumber',
  manageInventoryMovementsAllowed = 'shared.enums.userLevelSettingsDescription.manageInventoryMovements',
  inventoryCreationAllowed = 'shared.enums.userLevelSettingsDescription.inventoryCreation',
  removeExpirationDateAllowed = 'shared.enums.userLevelSettingsDescription.removeExpirationDates',
  modifyReplenishmentRequestsAllowed = 'shared.enums.userLevelSettingsDescription.modifyReplenishmentRequests',
  transferInventoryFromCustodianOnlyAllowed = 'shared.enums.userLevelSettingsDescription.transferInventoryFromCustodianOnlyAllowed'
}
