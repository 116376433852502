import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CatalogModel } from '@shared/models';
import { TrackById } from '@shared/utils/form-elements/track-by';

@Component({
  selector: 'app-create-quote-searched-items',
  templateUrl: './create-quote-searched-items.component.html',
  styleUrls: ['./create-quote-searched-items.component.scss'],
  standalone: false
})
export class CreateQuoteSearchedItemsComponent {
  @Input() searchedCatalogs: CatalogModel[] = [];
  @Input() selectedCatalogs: CatalogModel[] = [];
  @Input('loading') set loadingData(data: boolean) {
    if (data) {
      this.selectedView = 'SEARCHED';
    }

    this.loading = data;
  }

  @Output() selectCatalogEmitter = new EventEmitter<CatalogModel>();
  @Output() removeCatalogEmitter = new EventEmitter<CatalogModel>();

  selectedView: 'SEARCHED' | 'SELECTED' = 'SEARCHED';
  loading: boolean = false;

  readonly trackById = TrackById;
}
