import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { LanguageService } from '@services/internal/language.service';

@Component({
  selector: 'app-item-quantity',
  templateUrl: './item-quantity.component.html',
  styleUrls: ['./item-quantity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ItemQuantityComponent {
  @Input() quantity: number = 0;
  @Input() allowZeroValue: boolean = false;
  @Input() disableControls: boolean = false;
  @Input() hideControls: boolean = false;
  @Input() label: string = LanguageService.instant('shared.labels.qty');
  @Input() skipMaxWidth: boolean = false;

  @Output() quantityChanged = new EventEmitter<number>();

  readonly isMobile$: BehaviorSubject<boolean> = GlobalStoreService.isMobile$;

  /**
   * Calculate +1 or -1 when user click +- buttons on UI
   */
  calculate(isIncrement: boolean = false): void {
    const quantity: number = isIncrement ? this.quantity + 1 : this.quantity - 1;
    this.quantityChanged.emit(quantity);
  }

  validate(value: number): number {
    let quantity: number;

    if (Number(value) === 0) {
      quantity = 1;
    } else {
      quantity = value;
    }

    return quantity;
  }

  edit(value: string | number): void {
    this.quantityChanged.emit(Number(value));
  }
}
