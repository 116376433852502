import { HttpClient, HttpContext } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { AlertsService } from '@services/internal/alerts.service';
import { SKIP_AUTH_TOKEN } from '@constants';

export class ApiService {
  protected httpClient: HttpClient = inject(HttpClient);
  protected alertsService: AlertsService = inject(AlertsService);
  protected readonly apiUrlCore: string = environment.apiUrlCore;
  protected readonly apiUrlAuth: string = environment.apiUrlAuth;
  protected readonly apiUrlGudid: string = environment.apiUrlGudid;
  protected readonly apiUrlJob: string = environment.apiUrlJob;
  protected readonly apiUrlAI: string = environment.apiUrlAI;
  protected readonly apiUrlUDIdentify: string = environment.apiUrlUDIdentify;

  // Core service
  get<T>(path: string, options: { [param: string]: any } = {}, skipAuth: boolean = false): Observable<T> {
    return this.httpClient.get<T>(`${this.apiUrlCore}${path}`, this.getOptionsWithContext(options, skipAuth));
  }

  post<T>(path: string, body: any, options: { [param: string]: any } = {}, skipAuth: boolean = false): Observable<T> {
    return this.httpClient.post<T>(`${this.apiUrlCore}${path}`, body, this.getOptionsWithContext(options, skipAuth));
  }

  patch<T>(path: string, body: any, options: { [param: string]: any } = {}, skipAuth: boolean = false): Observable<T> {
    return this.httpClient.patch<T>(`${this.apiUrlCore}${path}`, body, this.getOptionsWithContext(options, skipAuth));
  }

  put<T>(path: string, body: any, options: { [param: string]: any } = {}, skipAuth: boolean = false): Observable<T> {
    return this.httpClient.put<T>(`${this.apiUrlCore}${path}`, body, this.getOptionsWithContext(options, skipAuth));
  }

  delete<T>(path: string, options: { [param: string]: any } = {}, skipAuth: boolean = false, body: any = null): Observable<T> {
    let httpOptions = this.getOptionsWithContext(options, skipAuth);
    if (body) {
      httpOptions = { ...httpOptions, ...{ body: body } };
    }
    return this.httpClient.delete<T>(`${this.apiUrlCore}${path}`, httpOptions);
  }

  // Auth service
  postAuth<T>(path: string, body: any, options: { [param: string]: any } = {}, skipAuth: boolean = false): Observable<T> {
    return this.httpClient.post<T>(`${this.apiUrlAuth}${path}`, body, this.getOptionsWithContext(options, skipAuth));
  }

  getAuth<T>(path: string, options: { [param: string]: any } = {}, skipAuth: boolean = false): Observable<T> {
    return this.httpClient.get<T>(`${this.apiUrlAuth}${path}`, this.getOptionsWithContext(options, skipAuth));
  }

  // Job service
  postJob<T>(path: string, body: any, options: { [param: string]: any } = {}, skipAuth: boolean = false): Observable<T> {
    return this.httpClient.post<T>(`${this.apiUrlJob}${path}`, body, this.getOptionsWithContext(options, skipAuth));
  }

  // Gudid service
  getGudid<T>(path: string, options: { [param: string]: any } = {}, skipAuth: boolean = false): Observable<T> {
    return this.httpClient.get<T>(`${this.apiUrlGudid}${path}`, this.getOptionsWithContext(options, skipAuth));
  }

  // AI service
  getAI<T>(path: string, options: { [param: string]: any } = {}, skipAuth: boolean = false): Observable<T> {
    return this.httpClient.get<T>(`${this.apiUrlAI}${path}`, this.getOptionsWithContext(options, skipAuth));
  }

  postAi<T>(path: string, body: any, options: { [param: string]: any } = {}, skipAuth: boolean = false): Observable<T> {
    return this.httpClient.post<T>(`${this.apiUrlAI}${path}`, body, this.getOptionsWithContext(options, skipAuth));
  }

  // UDIdentify service
  getUDIdentify<T>(path: string, options: { [param: string]: any } = {}, skipAuth: boolean = false): Observable<T> {
    return this.httpClient.get<T>(`${this.apiUrlUDIdentify}${path}`, this.getOptionsWithContext(options, skipAuth));
  }

  postUDIdentify<T>(path: string, body: any, options: { [param: string]: any } = {}, skipAuth: boolean = false): Observable<T> {
    return this.httpClient.post<T>(`${this.apiUrlUDIdentify}${path}`, body, this.getOptionsWithContext(options, skipAuth));
  }

  putUDIdentify<T>(path: string, body: any, options: { [param: string]: any } = {}, skipAuth: boolean = false): Observable<T> {
    return this.httpClient.put<T>(`${this.apiUrlUDIdentify}${path}`, body, this.getOptionsWithContext(options, skipAuth));
  }

  deleteUDIdentify<T>(path: string, options: { [param: string]: any } = {}, skipAuth: boolean = false): Observable<T> {
    return this.httpClient.delete<T>(`${this.apiUrlUDIdentify}${path}`, this.getOptionsWithContext(options, skipAuth));
  }

  private getOptionsWithContext(options: { [param: string]: any } = {}, skipAuth: boolean = false) {
    const context = new HttpContext().set(SKIP_AUTH_TOKEN, skipAuth);
    return { ...options, context };
  }
}
