import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalTooltipComponent } from './modal-tooltip/modal-tooltip.component';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { LanguageService } from '@services/internal/language.service';
import { TIcons } from '@shared/type/icons.type';
import { BehaviorSubject } from 'rxjs';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { Dialog } from '@capacitor/dialog';
import { TTooltipPosition } from '@shared/type/tooltip-position.type';

@Component({
  selector: 'app-tooltip-kit',
  templateUrl: './tooltip-kit.component.html',
  styleUrls: ['./tooltip-kit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class TooltipKitComponent {
  @ViewChild('tooltipContent') tooltipContent: ElementRef;

  @Input() title: TranslationsKeys;
  @Input() position: TTooltipPosition = 'left';
  @Input() isHtml: boolean = false;
  @Input() defaultCursor: boolean = true;
  @Input() size: 16 | 20 | 24 = 20;

  @Input() icon: TIcons = 'help';
  @Input() color: 'primary' | 'default' | 'primary_text' | 'success' | 'warn' | 'white' | 'orange' | 'blue' | 'overflow' = 'default';
  @Input() type: 'filled' | 'outline' = 'outline';

  isMobile$: BehaviorSubject<boolean> = GlobalStoreService.isMobile$;

  constructor(private dialog: MatDialog) {}

  showModalTooltip(): void {
    if (this.isMobile$.value) {
      if (GlobalStoreService.getPlatform() === 'web') {
        this.dialog.open(ModalTooltipComponent, {
          width: '270px',
          height: 'auto',
          hasBackdrop: true,
          panelClass: 'tooltipModalWindow',
          scrollStrategy: new NoopScrollStrategy(),
          data: this.title ? LanguageService.instant(this.title) : this.tooltipContent.nativeElement.innerHTML
        });
      } else {
        Dialog.alert({
          title: 'Info',
          message: this.title ? LanguageService.instant(this.title) : this.tooltipContent.nativeElement.innerHTML,
          buttonTitle: 'Ok'
        });
      }
    }
  }
}
