import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Optional, Output } from '@angular/core';
import { ISwitchOrganization, IUserOrganizationDto } from '@shared/interfaces';
import { firstValueFrom, map, Observable } from 'rxjs';
import { AuthenticationService } from '@services/auth.service';
import { UsersService } from '@services/users.service';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { LocalStorage } from '@services/internal/localstorage.service';
import { TrackByOrgId } from '@shared/utils/form-elements/track-by';
import { UserModel } from '@shared/models';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
declare let pendo: any;

@Component({
  selector: 'app-organization-switcher',
  templateUrl: './organization-switcher.component.html',
  styleUrls: ['./organization-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class OrganizationSwitcherComponent extends DestroySubscriptions {
  @Input() lineView: boolean = false;

  @Output() organizationsLoadedEmitter = new EventEmitter<IUserOrganizationDto[]>();
  @Output() closeSwitcherEmitter = new EventEmitter<void>();

  organizations: IUserOrganizationDto[] = [];
  currentOrgId: string = '';
  selectedOrgId: string = '';
  showOrgSwitcher: boolean;
  organizationName$: Observable<string> = UsersService.user$.pipe(map(u => u.organization.company.name));
  readonly trackBy = TrackByOrgId;

  constructor(
    private usersService: UsersService,
    private authenticationService: AuthenticationService,
    private ref: ChangeDetectorRef,
    private router: Router,
    @Optional() @Inject(MAT_DIALOG_DATA) public organizationsData: IUserOrganizationDto[]
  ) {
    super();
    if (organizationsData?.length) {
      this.organizations = organizationsData;
      this.currentOrgId = UsersService.getUser().organization.id;
      this.selectedOrgId = this.currentOrgId;
    } else {
      this.loadOrganizations();
    }
  }

  async switchOrg(organizationId: string): Promise<void> {
    if (organizationId === this.currentOrgId) {
      this.showOrgSwitcher = false;
      return;
    }
    const tokens: ISwitchOrganization = {
      accessToken: AuthenticationService.getToken(),
      organizationId
    };
    const result: boolean = await firstValueFrom(this.authenticationService.switchOrganization(tokens));
    if (result) {
      await this.router.navigate(['/dashboard']);
      LocalStorage.removeItem('dashboard');
      if (typeof pendo?.clearSession === 'function') {
        pendo.clearSession();
      }
      document.location.reload();
    }
  }

  /**
   * Load all organizations assigned to current user email
   */
  private async loadOrganizations(): Promise<void> {
    const user: UserModel = UsersService.getUser();
    this.currentOrgId = user.organization.id;
    const response = await firstValueFrom(this.usersService.findOrganizationByEmail(user.emailAddress));
    this.organizations = response.filter(org => org.organizationId !== this.currentOrgId);
    this.organizations.unshift(response.find(org => org.organizationId === this.currentOrgId));
    this.organizationsLoadedEmitter.emit(this.organizations);
    this.ref.markForCheck();
  }
}
